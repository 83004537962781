/* Tippy tooltip */
body.skin-light .tippy-tooltip {
	border: 1px solid rgba(0, 0, 0, 0.25);
}
body.skin-dark .tippy-tooltip {
	border: 1px solid #666;
}
body.skin-dark .tippy-popper[x-placement^=top] .tippy-tooltip:not(.light-theme) [x-arrow] {
	border-top-color: #666;
}
body.skin-dark .tippy-popper[x-placement^=bottom] .tippy-tooltip:not(.light-theme) [x-arrow] {
	border-bottom-color: #666;
}
body.skin-dark .tippy-popper[x-placement^=right] .tippy-tooltip:not(.light-theme) [x-arrow] {
	border-right-color: #666;
}
body.skin-dark .tippy-popper[x-placement^=left] .tippy-tooltip:not(.light-theme) [x-arrow] {
	border-left-color: #666;
}