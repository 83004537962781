.stacktrace-dialog .dialog-content-component .title.custom {
	margin-bottom: 0;
}
.stacktrace-dialog .dialog-content-component .content::after {
	display: none;
}
.stacktrace-dialog .dialog-content {
	height: 80vh;
}
.stacktrace-dialog .dialog-content-component {
	height: 100%;
}

.stacktrace-dialog .dialog-content-component .toolbar.standard .button-component .icon,
.stacktrace-dialog .dialog-content-component .toolbar.standard .button .icon
{
	font-size: 14px;
}

