.wrapper {
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
}

.toolbar {
	cursor: pointer;
}

.wrapper .groups {
	flex: 1 1 100%;
	min-width: 0;
	overflow-y: auto;
}
.wrapper.collapsed .groups {
	display: none;
}

.wrapper .group {
	font-weight: bold;
	font-size: 0.85rem;
}

.wrapper .item {
	margin-left: 1em;
	font-weight: normal;
}

.wrapper .itemInner {
	position: relative;
	display: block;
}

.wrapper .item .itemHelpIcon {
	color: var(--icon-action-color);
	padding: 0 5px;
	margin: 0;
}