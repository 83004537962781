#report-templates-page .main-search .options-form-inner {
	display: flex;
	flex-flow: column wrap;
	max-height: 250px;
	max-width: 100%;
	overflow: visible;
	padding-right: 10px;
	margin-top: 12px;
}
#report-templates-page .main-search .options-form-inner .form-field.label-position-aligned {
	width: 50%;
	margin: 0.75rem 0;
}

#report-templates-page #main-page-table thead th:nth-last-child(2),
#report-templates-page #main-page-table tbody td:nth-last-child(2)
{
	border-right: none;
}