.wrapper {
	
}

.wrapper .dateFormatDropdown,
.wrapper .dateFormatDropdownLabel,
.wrapper .dateFormatButton {
	height: var(--input-height);
}
.wrapper .dateFormatDropdownLabel {
	display: block;
}
.wrapper .dateFormatButton {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	margin: 0;
}
.wrapper .dateFormatDropdownContent {
	width: 180px;
}
.wrapper .dateFormatDropdownGroup {
	font-size: 0.75rem;
}
.wrapper .dateFormatDropdownItem {
	font-size: 0.75rem;
}

.wrapper .link {
	cursor: pointer;
}