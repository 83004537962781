/* TODO: Implement skin */
.react-toggle {
	--thumb-outline-color: var(--action-color-faded);
	
	touch-action: pan-x;

	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
}

.react-toggle.readonly {
	opacity: 1;
	cursor: default;
}

.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}

.react-toggle-track {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: #4D4D4D;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
	position: relative;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	box-shadow: inset 0 0 0 999em rgba(0, 0, 0, 0.25);
}

.react-toggle--checked .react-toggle-track {
	background-color: var(--default-background);
	box-shadow: inset 0 0 0 999em transparent;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {

}

.react-toggle-track-check {
	position: absolute;
	width: 14px;
	top: 0;
	bottom: 0;
	line-height: 0;
	left: 8px;
	opacity: 0;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-size: 11px;
}

.react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle-track-x {
	position: absolute;
	width: 10px;
	top: 0;
	bottom: 0;
	line-height: 0;
	right: 10px;
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-size: 11px;
}

.react-toggle--checked .react-toggle-track-x {
	opacity: 0;
}

.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 2px;
	left: 2px;
	width: 20px;
	height: 20px;
	border: none;
	border-radius: 50%;
	background-color: #FAFAFA;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
	left: 28px;
}

.react-toggle--focus .react-toggle-thumb {
	-webkit-box-shadow: 0 0 0 4px var(--thumb-outline-color);
	-moz-box-shadow: 0 0 0 4px var(--thumb-outline-color);
	box-shadow: 0 0 0 4px var(--thumb-outline-color);
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	-webkit-box-shadow: 0 0 0 6px var(--thumb-outline-color);
	-moz-box-shadow: 0 0 0 6px var(--thumb-outline-color);
	box-shadow: 0 0 0 6px var(--thumb-outline-color);
}
