.wrapper {
	
}

.wrapper .editorSection {
	display: flex;
	flex-flow: row nowrap;
}

.wrapper .dynamicValues {
	flex: 0 0 250px;
}

.wrapper .editor {
	flex: 1 1 100%;
}

.wrapper .dynamicValueComponent {
	height: 300px;
	border: 1px solid var(--sidebar-border-color);
}

.wrapper .editorComponent {
	width: calc(100% - 20px);
	height: 100%;
	margin-left: 20px;
	font: var(--font-main-mono);
	font-size: 0.875rem;
}

.wrapper .formulaEditor {
	height: 360px !important;
}