@font-face {
	font-family: MainSans;
	src: url('../fonts/public_sans/PublicSans-VariableFont_wght.ttf') format('truetype-variations');
	font-variation-settings: 'ital' 1;
	/*noinspection CssUnknownProperty*/
	font-synthesis: none;
}

@font-face {
	font-family: MainMono;
	src: url('../fonts/roboto_mono/RobotoMono-VariableFont_wght.ttf') format('truetype-variations');
	font-variation-settings: 'ital' 1;
	/*noinspection CssUnknownProperty*/
	font-synthesis: none;
}
