/*
	Default PasswordResetRequest component style
	@see 'styleName' prop of the component.
*/

.password-reset-request-component.default-style {
	width: 100%;
	max-width: 500px;
}

.password-reset-request-component.default-style .card-header {
	text-align: left;
}

.password-reset-request-component.default-style .app-name {
	text-align: center;
	font-weight: 600;
	font-size: 0.5em;
	margin-top: -10px;
	margin-bottom: 40px;
}

.password-reset-request-component.default-style .card-content {
	padding: 20px 40px 40px;
	width: 100%;
	margin: 0 auto;
	overflow: visible;
}

.password-reset-request-component.default-style .card-content .form-field-label {
	font-size: 14px;
	font-weight: 200;
	color: var(--text-color-faded);
}
.password-reset-request-component.default-style .error .card-content .form-field-label {
	color: var(--error-background);
}

.password-reset-request-component.default-style .card-content .form-field-input {
	font-size: 16px;
}

.password-reset-request-component.default-style .component-error {
	margin: 0;
	padding-bottom: 1em;
	text-align: center;
	background: var(--card-background-fade);
}