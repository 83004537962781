.form-field {
	--input-border-radisu: .25em;
	--input-font-size: 1rem;
	--input-height: 38px;

	min-height: var(--input-height);
}

/* Input toolbar */
.input-toolbar {
	display: flex;
	flex-flow: row nowrap;
}
.input-toolbar.position-left {
	order: -1;
}
.input-toolbar.position-right {
	order: 99;
}
.input-toolbar .insert-value-button-component,
.select-input-component .insert-value-button-component 
{
	order: -99;
}
.form-control-wrapper .input-toolbar .input-toolbar-button,
.form-control .input-toolbar-button,
.input-toolbar .input-toolbar-button-wrapper .button-component,
.datepicker.form-control [class*="picker__button"],
.select-input-component.standard .select-input__indicator, 
.select-input-component.form-control .select-input__indicator
{
	padding: 4px 6px;
	height: 100%;
	margin: 0;
	font-size: var(--input-font-size);
	border: none;
	outline: none;
	transition: var(--transition-hover-out);
	border-radius: 0;
	cursor: pointer;
	color: var(--icon-action-color);
	opacity: 1;
}
.input-toolbar .input-toolbar-button-wrapper > .file-input-component {
	cursor: pointer;
}
.select-input-component.standard .select-input__loading-indicator,
.select-input-component.form-control .select-input__loading-indicator 
{
	font-size: 4px;
}
.form-control-wrapper .input-toolbar .input-toolbar-button:hover:not(:disabled),
.form-control .input-toolbar-button:hover:not(:disabled),
.input-toolbar .input-toolbar-button-wrapper:hover:not(:disabled) .button-component,
.datepicker.form-control [class*="picker__button"]:hover:not(:disabled),
.select-input-component.standard .select-input__indicator:hover:not(:disabled),
.select-input-component.form-control .select-input__indicator:hover:not(:disabled)
{
	color: var(--icon-action-color-hover);
	opacity: 1;
	transition: var(--transition-hover-in);
	box-shadow: none;
}
.form-control-wrapper .input-toolbar .input-toolbar-button:focus,
.form-control .input-toolbar-button:focus,
.input-toolbar .input-toolbar-button-wrapper:focus .button-component,
.datepicker.form-control [class*="picker__button"]:focus,
.select-input-component.standard .select-input__indicator:focus,
.select-input-component.form-control .select-input__indicator:focus
{
	color: var(--icon-action-color-hover);
	opacity: 1;
	outline: none;
	box-shadow: none;
}
.form-control-wrapper .input-toolbar .input-toolbar-button:active:not(:disabled),
.form-control .input-toolbar-button:active:not(:disabled),
.input-toolbar .input-toolbar-button-wrapper:active:not(:disabled) .button-component,
.datepicker.form-control [class*="picker__button"]:active:not(:disabled),
.select-input-component.standard .select-input__indicator:active:not(:disabled),
.select-input-component.form-control .select-input__indicator:active:not(:disabled)
{
	opacity: 1;
	outline: none;
	box-shadow: none;
}
.form-control-wrapper .input-toolbar .input-toolbar-button .icon,
.input-toolbar .input-toolbar-button-wrapper .button-component .icon,
.form-control .input-toolbar-button .icon,
.datepicker.form-control [class*="picker__button"] .icon
{
	line-height: 1;
}
.select-input-component.standard .select-input__indicator,
.select-input-component.form-control .select-input__indicator {
	display: flex;
	justify-content: center;
	align-items: center;
}
.select-input-component.standard .select-input__indicator svg,
.select-input-component.form-control .select-input__indicator svg {
	height: var(--input-font-size);
	width: var(--input-font-size);
}
.form-field.error .form-control-wrapper .input-toolbar .input-toolbar-button,
.form-field.error .form-control .input-toolbar-button,
.form-field.error .input-toolbar .input-toolbar-button-wrapper .button-component,
.form-field.error .datepicker.form-control [class*="picker__button"],
.form-field.error .select-input-component.standard .select-input__indicator,
.form-field.error .select-input-component.form-control .select-input__indicator
{
	border-color: var(--error-background);
	background: hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) + 40%));
	color: var(--error-background);
}
.form-field.error .select-input-component.standard .select-input__input,
.form-field.error .select-input-component.form-control .select-input__input {
	color: var(--error-background);
}


.form-control-wrapper label,
.form-control {
	font: var(--font-main-input);
	padding: .375rem .5rem;
}
.form-control-wrapper.no-padding label {
	padding: 0;
}
.form-control-wrapper.cursor-pointer label {
	cursor: pointer;
}

.form-control-wrapper label {
	border-radius: var(--input-border-radisu);
	border: var(--form-control-border);
	background: var(--form-control-label-background);
	color: var(--form-control-label-text);
	flex-grow: 0;
	flex-shrink: 0;
	display: block;
}
.form-control-wrapper.left-label label {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	border-right: none;
}
.form-control-wrapper.right-label label {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-left: none;
}

.form-control {
	display: block;
	font-weight: 400;
	font-size: var(--input-font-size);
	line-height: 1.5;
	background: var(--form-control-background);
	background-clip: padding-box;
	border: var(--form-control-border);
	border-radius: var(--input-border-radisu);
	color: var(--form-control-text);
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.form-control:not(:read-only):not(:disabled):focus
{
	border-color: var(--action-color-main);
	outline: 0;
	box-shadow: 0 0 0 .2rem var(--action-color-faded);
	z-index: 1;
	position: relative;
}
/* Fix for Firefox file input. It must be separate definition in order for Chrome to work properly!? */
.form-control[type='file']:-moz-focusring {
	border-color: var(--action-color-main);
	outline: 0;
	box-shadow: 0 0 0 .2rem var(--action-color-faded);
	z-index: 1;
	position: relative;
}
.form-control:disabled {
	color: var(--form-control-placeholder-text);
}
.form-control-wrapper.left-label .form-control {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}
.form-control-wrapper.right-label .form-control {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}
.form-control::placeholder {
	color: var(--form-control-placeholder-text);
}


/* Text input */
.text-input-label > .preview-content .icon.input-button {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
}


/* Textarea */
textarea.form-control {
	height: auto;
	min-height: 150px;
}


/* File input */
.file-input-component.form-control {
	padding: calc(var(--input-height) / 2 - 14px);
}


/* React DatePicker, DateRangeInput and DateTimePicker */
.datepicker.form-control {
	display: inline-flex;
}
.datepicker.form-control[class*="picker--open"] {
	z-index: 3;
}
.datepicker.form-control [class*="picker__wrapper"] {
	border: none;
	max-width: 100%;
}
.datepicker.form-control[class*="picker--open"]:not([class*="picker--disabled"]) {
	border-color: var(--action-color-main);
	outline: 0;
	box-shadow: 0 0 0 .2rem var(--action-color-faded);
}
.datepicker.form-control [class*="picker__inputGroup__input"] {
	color: inherit;
	max-width: 100%;
}
.datepicker.form-control [class*="picker__inputGroup__input"]:invalid {
	background: hsla(var(--error-background-h), var(--error-background-s), var(--error-background-l), 15%);
	color: hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) + 15%));
}
.datepicker.form-control [class*="picker__calendar-button"] { order: 1; }
.datepicker.form-control [class*="picker__inputGroup"] { order: 99; }
.datepicker.form-control [class*="picker__range-divider"] { order: 99; }
.datepicker.form-control [class*="picker__clear-button"] { order: 100; }

.datepicker.form-control .calendar {
	font: var(--font-main-input);
	background: var(--calendar-background);
	border: var(--calendar-border);
	box-shadow: var(--calendar-shadow);
	color: var(--calendar-text);
}
.datepicker.form-control .react-calendar__navigation button,
.datepicker.form-control .react-calendar__navigation button:hover,
.datepicker.form-control .react-calendar__navigation button:focus,
.datepicker.form-control .react-calendar__tile,
.datepicker.form-control .react-calendar__tile:hover,
.datepicker.form-control .react-calendar__tile:focus,
.datepicker.form-control .react-calendar__tile--now,
.datepicker.form-control .react-calendar__tile--now:hover,
.datepicker.form-control .react-calendar__tile--now:focus,
.datepicker.form-control .react-calendar__tile--rangeStart,
.datepicker.form-control .react-calendar__tile--rangeStart:hover,
.datepicker.form-control .react-calendar__tile--rangeStart:focus,
.datepicker.form-control .react-calendar__tile--range,
.datepicker.form-control .react-calendar__tile--range:hover,
.datepicker.form-control .react-calendar__tile--range:focus,
.datepicker.form-control .react-calendar__tile--rangeEnd,
.datepicker.form-control .react-calendar__tile--rangeEnd:hover,
.datepicker.form-control .react-calendar__tile--rangeEnd:focus
{
	background: none;
	color: var(--calendar-text);
	border: 1px solid transparent;
	border-radius: var(--input-border-radisu);
}
.datepicker.form-control .react-calendar__navigation button:hover:enabled,
.datepicker.form-control .react-calendar__tile:hover:enabled,
.datepicker.form-control .react-calendar__tile--now:hover:enabled,
.datepicker.form-control .react-calendar__tile--range:hover:enabled,
.datepicker.form-control .react-calendar__tile--rangeStart:hover:enabled,
.datepicker.form-control .react-calendar__tile--rangeEnd:hover:enabled
{
	border: 1px solid var(--calendar-hover);
}
.datepicker.form-control .react-calendar__navigation button:focus,
.datepicker.form-control .react-calendar__tile:focus,
.datepicker.form-control .react-calendar__tile--now:focus,
.datepicker.form-control .react-calendar__tile--range:focus,
.datepicker.form-control .react-calendar__tile--rangeStart:focus,
.datepicker.form-control .react-calendar__tile--rangeEnd:focus
{
	border-color: var(--action-color-main);
	outline: 0;
	box-shadow: 0 0 0 .2rem var(--action-color-faded);
}
.datepicker.form-control .react-calendar__navigation button:disabled,
.datepicker.form-control .react-calendar__tile:disabled,
.datepicker.form-control .react-calendar__tile--now:disabled,
.datepicker.form-control .react-calendar__tile--range:disabled,
.datepicker.form-control .react-calendar__tile--rangeStart:disabled,
.datepicker.form-control .react-calendar__tile--rangeEnd:disabled
{
	opacity: var(--form-control-disabled-opacity);
	cursor: not-allowed;
}

.datepicker.form-control .react-calendar__month-view__days__day--weekend,
.datepicker.form-control .react-calendar__month-view__days__day--weekend:hover,
.datepicker.form-control .react-calendar__month-view__days__day--weekend:focus
{
	color: var(--calendar-text-weekend);
}

.datepicker.form-control .react-calendar__tile--now,
.datepicker.form-control .react-calendar__tile--now:hover
{
	background: var(--calendar-now-background);
}

.datepicker.form-control .react-calendar__tile--range,
.datepicker.form-control .react-calendar__tile--range:hover {
	background: var(--action-color-faded);
	border-radius: 0;
}

.datepicker.form-control .react-calendar__tile--rangeStart,
.datepicker.form-control .react-calendar__tile--rangeStart:hover,
.datepicker.form-control .react-calendar__tile--rangeEnd,
.datepicker.form-control .react-calendar__tile--rangeEnd:hover
{
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
	border-radius: var(--input-border-radisu);
}
.datepicker.form-control .react-calendar__tile--rangeStart {
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}
.datepicker.form-control .react-calendar__tile--rangeEnd {
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}

.datepicker.form-control.readonly {
	cursor: default;
}

.datepicker.form-control.react-date-picker--disabled {
	color: var(--form-control-placeholder-text);
	opacity: var(--form-control-disabled-opacity);
	background: var(--form-control-background);
}


/* Select input */
.select-input-component.standard:not(.insert-value-input),
.select-input-component.form-control:not(.insert-value-input) {
	border: none;
	padding: 0;
	margin: 0;
}
.select-input-component.standard .select-input__single-value,
.select-input-component.form-control .select-input__single-value {
	color: var(--form-control-text);
}
.select-input-component.standard.select-input--is-disabled,
.select-input-component.form-control.select-input--is-disabled {
	opacity: var(--form-control-disabled-opacity);
}
.select-input-component.standard .select-input__control,
.select-input-component.form-control .select-input__control {
	font-weight: 400;
	line-height: 1.5;
	background: var(--form-control-background);
	background-clip: padding-box;
	border: var(--form-control-border);
	border-radius: var(--input-border-radisu);
	color: var(--form-control-text);
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	margin: 0;
	min-height: var(--input-height);
	height: 100%;
	display: flex;
	flex-flow: row nowrap;
}
.select-input-component.standard.select-input--is-disabled .select-input__control,
.select-input-component.form-control.select-input--is-disabled .select-input__control {
	cursor: not-allowed;
	pointer-events: auto;
	touch-action: auto;
}
.select-input-component.standard.select-input--is-disabled .select-input__control > *,
.select-input-component.form-control.select-input--is-disabled .select-input__control > * {
	pointer-events: none;
	touch-action: none;
}
.select-input-component.standard .select-input__control.select-input__control--is-focused,
.select-input-component.form-control .select-input__control.select-input__control--is-focused {
	border-color: var(--action-color-main);
	outline: 0;
	box-shadow: 0 0 0 .2rem var(--action-color-faded);
}
.select-input-component.standard .select-input__value-container,
.select-input-component.form-control .select-input__value-container {
	padding-left: .5rem;
	padding-right: .5rem;
}
.select-input-component.standard .select-input__value-container--is-multi.select-input__value-container--has-value,
.select-input-component.form-control .select-input__value-container--is-multi.select-input__value-container--has-value {
	padding-left: 4px;
}
.select-input-component.standard .select-input__indicator,
.select-input-component.form-control .select-input__indicator {
	color: var(--icon-action-color);
	padding-top: 6px;
	padding-bottom: 6px;
}
.select-input-component.standard .select-input__indicator:hover,
.select-input-component.form-control .select-input__indicator:hover {
	color: var(--icon-action-color-hover);
}
.select-input-component.standard .select-input__menu,
.select-input-component.form-control .select-input__menu,
.select-input__menu-portal .select-input__menu {
	margin: -1px 0 0 0;
	background: var(--calendar-background);
	color: var(--calendar-text);
	box-shadow: var(--calendar-shadow);
	z-index: 5;
}
.select-input-component.standard .select-input__menu-list,
.select-input-component.form-control .select-input__menu-list,
.select-input__menu-portal .select-input__menu-list {
	padding: 0;
	border: var(--calendar-border);
	border-radius: var(--dropdown-border-radius);
}
.select-input-component.standard .select-input__option--is-focused,
.select-input-component.form-control .select-input__option--is-focused,
.select-input__menu-portal .select-input__option--is-focused {
	background: var(--calendar-now-background);
}
.select-input-component.standard .select-input__option--is-selected,
.select-input-component.form-control .select-input__option--is-selected,
.select-input__menu-portal .select-input__option--is-selected {
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.select-input-component.standard .select-input__indicator-separator,
.select-input-component.form-control .select-input__indicator-separator {
	display: none;
}
.select-input-component.standard.show-indicator-separator .select-input__indicator-separator,
.select-input-component.form-control.show-indicator-separator .select-input__indicator-separator {
	display: inline;
	background: var(--icon-action-color);
	opacity: 0.5;
}
.select-input-component.standard .select-input__multi-value,
.select-input-component.form-control .select-input__multi-value {
	background: var(--calendar-now-background);
}
.select-input-component.standard .select-input__multi-value__label,
.select-input-component.form-control .select-input__multi-value__label {
	color: var(--calendar-text);
}
.select-input-component.standard .select-input__multi-value__remove:hover,
.select-input-component.form-control .select-input__multi-value__remove:hover {
	background: var(--error-background);
	color: var(--error-text);
}
.select-input-component.standard .select-input__input,
.select-input-component.form-control .select-input__input {
	color: var(--calendar-text);
}
.select-input-component .select-input__placeholder {
	color: var(--form-control-placeholder-text);
	opacity: 0.5;
	line-height: 1;
	white-space: nowrap;
}
.select-input-component.has-insert-btn .select-input__value-container { 
	padding-left: 0; 
}
.select-input-component .insert-value-button-component {
	margin-left: 0.5rem;
}


/* Form ------------------------------------------------------------------------------------------------------------- */
.form-wrapper {
	width: 100%;
	padding-top: 1px; /* Fix for top margin */
}

.form-field {
	display: flex;
	width: 100%;
	min-width: 0;
}
.form-field.half {
	width: calc(50% - 10px);
	float: left;
}
.form-field.half:nth-of-type(2n+1) {
	margin-right: 10px;
}
.form-field.half:nth-of-type(2n+0) {
	margin-left: 10px;
}
.form-field.half .form-control-wrapper,
.form-field.half .form-field-input {
	max-width: none;
}

.form-field.label-position-aligned {
	flex-flow: row nowrap;
	margin: 1.5rem 0;
}
.form-wrapper.labels-only .form-field.label-position-aligned {
	margin: 0;
}
.form-wrapper.labels-only .form-field.label-position-aligned:nth-child(even) {
	background: var(--table-row-even-background);
}
.form-wrapper.labels-only .form-field.label-position-aligned:nth-child(odd) {
	background: var(--table-row-odd-background);
}
.form-wrapper.labels-only .form-field .tag {
	font-size: 0.85em;
}
.form-field.label-position-stacked {
	flex-flow: column nowrap;
	margin: 0;
}

.form-field-label {
	line-height: 1;
	font-weight: 600;
	cursor: default;
}
.form-field-label.disabled {
	color: var(--form-control-placeholder-text);
	cursor: not-allowed;
}
.form-field.label-position-none > .form-field-label {
	display: none;
}
.form-field.label-position-aligned > .form-field-label {
	margin-right: 1.5rem;
	flex-basis: calc(var(--form-label-width) - 1.5rem);
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	text-align: right;
}
.label-auto-size .form-field.label-position-aligned > .form-field-label {
	flex-basis: 0;
	white-space: nowrap;
}
.form-field.label-position-stacked > .form-field-label {
	margin-bottom: 0.5em;
}
.form-field.label-position-stacked > .form-field-label > .form-field-label-wrapper {
	display: inline-block;
}
.form-field-label .with-help {
	cursor: help;
}
.form-field.label-position-stacked > .form-field-label > .with-help {
	display: inline-flex !important;
}
.form-field-label .with-help:hover .label-help-icon {
	color: var(--icon-action-color-hover);
	transition: var(--transition-hover-in);
}

.form-field-input {
	flex-grow: 1;
	display: flex;
	flex-flow: row wrap;
	min-width: 0;
	max-width: var(--form-field-max-width);
	position: relative;
	align-content: center;
	align-items: center;
}
.form-field.label-position-none,
.form-field.label-position-stacked {
	margin-bottom: 1.5rem;
}
.form-field.label-position-none:last-child,
.form-field.label-position-stacked:last-child {
	margin-bottom: 1em;
}
.form-field-input .form-control {
	width: 100%;
	min-height: var(--input-height);
}
.form-field-input.small,
.form-field-input.small > .input-wrapper,
.form-field-input.small > .form-control {
	max-width: var(--form-filed-small-width);
}
.form-field-input.half,
.form-field-input.full > .input-wrapper,
.form-field-input.full > .form-control {
	max-width: calc(var(--form-field-max-width) / 2);
}
.form-field-input.full,
.form-field-input.full > .input-wrapper,
.form-field-input.full > .form-control {
	max-width: none;
}
.form-field-input.auto-width,
.form-field-input.auto-width > .input-wrapper,
.form-field-input.auto-width > .form-control
{
	width: auto;
	max-width: none;
	min-width: 0;
}

.form-field-input .form-field-multiple-inputs {
	height: 100%;
}
.form-field-input .form-field-multiple-inputs > div .form-control,
.form-field-input .form-field-multiple-inputs > div .select-input__control {
	border-radius: 0;
	margin-left: -1px;
}
.form-field-input .form-field-multiple-inputs > div .form-control-wrapper .text-input-label {
	margin-left: -1px;
}
.form-field-input .form-field-multiple-inputs > div:first-child .input-wrapper:not(.right-label) .form-control,
.form-field-input .form-field-multiple-inputs > div:first-child .select-input-component .select-input__control,
.form-field-input .form-field-multiple-inputs > div:first-child .input-wrapper:not(.right-label) .select-input__control {
	border-top-left-radius: var(--input-border-radisu);
	border-bottom-left-radius: var(--input-border-radisu);
	margin-left: 0;
}
.form-field-input .form-field-multiple-inputs > div:last-child .input-wrapper:not(.right-label) .form-control,
.form-field-input .form-field-multiple-inputs > div:last-child .select-input-component .select-input__control,
.form-field-input .form-field-multiple-inputs > div:last-child .input-wrapper:not(.right-label) .select-input__control {
	border-top-right-radius: var(--input-border-radisu);
	border-bottom-right-radius: var(--input-border-radisu);
}

.form-field-input > small {
	position: absolute;
	top: 100%;
	font-size: 0.75rem;
	color: var(--text-color-faded);
}

.form-field.error .form-control,
.form-field.error .form-control .select-input__control,
.form-field.error .select-input-component.standard .select-input__indicator svg,
.form-field.error .select-input-component.form-control .select-input__indicator svg
{
	border-color: var(--error-background);
	background: hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) + 40%));
	color: var(--error-background);
}
.form-field.error .form-control .select-input__control .select-input__single-value {
	color: var(--error-background);
}
.form-field.error .form-control::placeholder {
	color: hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) + 10%));
}
.form-field.error .form-control:not(:read-only):not(:disabled):focus {
	box-shadow: 0 0 0 .2rem hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) + 35%));
}
.form-field.error .form-field-input .formula-editor-component {
	background: hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) + 40%));
}
.form-field.error .formula-editor-component .toolbar,
.form-field.error .formula-editor-component .formula-editor,
.form-field.error .formula-editor-component .properties {
	border-color: var(--error-background);
}

.form-field.label-position-aligned .form-field-messages {
	margin-left: var(--form-label-width);
}

.form-field-label-required-icon.icon {
	font-size: 8px;
	vertical-align: super;
	margin-left: 1px;
	font-weight: normal;
	cursor: help;
}

.form-field-between > .form-field-input {
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
}
.form-field-between > .form-field-input > div {
	flex: 1 1 48%;
}
.form-field-between > .form-field-input > div .form-control,
.form-field-between > .form-field-input > div [class*="picker__inputGroup"] {
	text-align: center;
}
.form-field-between > .form-field-input > div .select-input__value-container {
	justify-content: center;
}
.form-field-between > .form-field-input > div:first-child,
.form-field-between > .form-field-input > div:first-child .form-control,
.form-field-between > .form-field-input > div:first-child .select-input__control
{
	border-right: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.form-field-between > .form-field-input > div:last-child,
.form-field-between > .form-field-input > div:last-child .form-control,
.form-field-between > .form-field-input > div:last-child .select-input__control
{
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.form-field-between > .form-field-input .between-separator {
	flex: 0 0 1%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--form-control-background);
	border-bottom: var(--form-control-border);
	color: var(--form-control-text);
}

.form-field.multi-form-field {
	align-items: flex-start;
}
.form-field.multi-form-field .form-field-label {
	padding-top: calc((var(--input-height) / 2) - 0.5em);
}

.form-field-input .form-control.checkbox-group {
	border-radius: var(--input-border-radisu);
}
.form-field-input .form-control.checkbox-group .checkbox-component {
	text-align: left;
	line-height: 1.1;
	padding: .5em;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
.form-field-input .form-control.checkbox-group .checkbox-component > .icon {
	font-size: 1.5em;
	height: 1em !important;
	width: 1em !important;
	color: unset;
	margin-right: .25em;
}
.form-field-input .form-control.checkbox-group .checkbox-component {
	align-items: center;
}

.form-field-input .form-control.radio-input-component {
	padding: 0;
}

.form-field input[type='range'].form-control {
	-webkit-appearance: none;
	background: transparent;
	border: none;
	padding: 0;
	margin: 0;
	opacity: 0.85;
}
.form-field input[type='range'].form-control:hover {
	opacity: 1;
}
.form-field input[type='range'].form-control:focus {
	outline: none;
	opacity: 1;
}
.form-field input[type='range'].form-control ~ .range-legend {
	position: relative;
	top: auto;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	margin-top: -.25rem;
}
.form-field input[type='range'].form-control ~ .range-legend > .current-value {
	color: var(--form-control-text);
	font-size: 1rem;
	margin-top: -.25rem;
}
.form-field.error input[type='range'].form-control ~ .range-legend > .current-value {
	color: var(--error-background);
}
.form-field input[type='range'].form-control::-webkit-slider-runnable-track {
	cursor: pointer;
	height: .75rem;
	background: var(--form-control-background);
	border-radius: var(--input-border-radisu);
	border: var(--form-control-border);
}
.form-field input[type='range'].form-control::-webkit-slider-thumb {
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 50%;
	background: var(--action-color-main);
	border: 4px solid var(--form-control-background);
	outline: var(--form-control-border);
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -.5rem;
}
.form-field input[type='range'].form-control:focus::-webkit-slider-runnable-track {
	border-color: var(--action-color-main);
	outline: 0;
	box-shadow: 0 0 0 0.2rem var(--action-color-faded);
	z-index: 1;
	position: relative;
}
.form-field input[type='range'].form-control:focus::-webkit-slider-thumb {
	outline-color: var(--action-color-main);
}
.form-field input[type='range'].form-control::-moz-range-track {
	width: 100%;
	height: .5rem;
	background: var(--form-control-background);
	border-radius: var(--input-border-radisu);
	border: var(--form-control-border);
}
.form-field input[type='range'].form-control::-moz-range-thumb {
	height: 1rem;
	width: 1rem;
	border-radius: 50%;
	background: var(--action-color-main);
	border: 4px solid var(--form-control-background);
	outline: var(--form-control-border);
	cursor: pointer;
	margin-top: -.5rem;
}
.form-field input[type='range'].form-control:focus::-moz-range-track {
	border-color: var(--action-color-main);
	outline: 0;
	box-shadow: 0 0 0 0.2rem var(--action-color-faded);
	z-index: 1;
	position: relative;
}
.form-field input[type='range'].form-control:focus::-moz-range-thumb {
	outline-color: var(--action-color-main);
}
.form-field input[type='range'].form-control::-moz-range-progress {
	height: .5rem;
	background: var(--action-color-main);
	border-radius: calc(var(--input-border-radisu) - 2px);
	border: var(--action-color-hover);
}

@media screen and (max-width: 640px) {
	#root .form-field.label-position-aligned {
		flex-flow: column nowrap;
	}

	#root .form-field.label-position-aligned > .form-field-label {
		flex: 1 1 auto;
		display: block;
		text-align: left;
		margin-left: 0;
		margin-bottom: 0.5rem;
	}

	#root .form-wrapper.labels-only .form-field.label-position-aligned {
		margin-bottom: 1rem;
	}
	#root .form-wrapper.labels-only .form-field.label-position-aligned > .form-field-label {
		margin: 0;
	}
}