.wrapper {
	
}

.wrapper .favouriteBtn {
	cursor: pointer;
	padding: 0.5em 1em;
	font-size: 1.1em;
	line-height: 1.15;
}
.wrapper .favouriteBtn.true {
	color: var(--palette-orange);
}
.wrapper .favouriteBtn.false {
	color: var(--text-color-faded);
}
.wrapper .statusCell {
	white-space: nowrap;
}
.wrapper .statusCell .pendingIcon {
	line-height: inherit;
}
.wrapper .statusPending {
	color: var(--text-color-faded);
}
.wrapper .statusProcessing {
	color: var(--text-color-faded);
}
.wrapper .statusCompleted {
	color: var(--text-color-main);
	font-weight: bold;
}
.wrapper .statusError {
	color: var(--error-background);
}
.wrapper .statusWarning {
	color: var(--warning-background);
}