.dropdown {
	position: relative;
}

.dropdown-label {
	cursor: pointer;

	/* No select */
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.dropdown-content {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.dropdown.show .dropdown-content {
	display: block;
}

.dropdown-content-inner {
	display: flex;
	flex-flow: column nowrap;
}