.wrapper {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	width: 100%;
	max-width: var(--form-field-max-width);
}
.wrapper.disabled {
	opacity: var(--form-control-disabled-opacity);
}
.wrapper.left-label {
	flex-direction: row;
	justify-content: flex-start;
}
.wrapper.right-label {
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.label {
	overflow: hidden;
}
.wrapper .clickable-label {
	cursor: pointer;
}
.wrapper.disabled .clickable-label {
	cursor: not-allowed;
}
.preview {
	line-height: 1.4;
	height: 100%;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: flex;
	justify-content: stretch;
	align-items: center;
}

.inputWrapper {
	position: relative;
	width: 100%;
}

.inputWrapper .errorIcon {
	position: absolute;
	top: 4px;
	right: 4px;
	color: var(--error-background);
	font-size: 12px;
	line-height: 1;
	cursor: help;
	z-index: 1;
}

.inputWrapper .input {
	width: 100%;
}

.inputWrapper .input:read-only {
	cursor: default;
}

.inputWrapper .input:disabled {
	cursor: not-allowed;
}

.inputWrapper .input-toolbar {
	display: flex;
	position: absolute;
	top: .375rem;
	z-index: 2;
	height: calc(100% - .375rem*2);
}
.inputWrapper .input-toolbar.position-left {
	left: calc(0.5rem + 1px); /* 1px is added because of the border */
}
.inputWrapper .input-toolbar.position-right {
	right: calc(0.5rem + 1px); /* 1px is added because of the border */
}