.report-builder-filter-item-component,
.report-builder-filter-item-component .form-field 
{
	--input-font-size: 0.875rem;
	--input-height: 22px
}

.report-builder-filter-item-component {
	display: flex;
	flex-flow: row nowrap;
	min-width: 836px;
	position: relative;
	padding: 16px 16px 16px 0;
	background-color: var(--background-color-secondary);
	margin-bottom: 6px;
	border-radius: 6px;
	transition: background-color 150ms ease-out;
}
.report-builder-filter-item-component:hover,
.report-builder-filter-item-component.sortable-chosen {
	background-color: var(--action-color-faded);
	transition: background-color 200ms ease-in;
}
.report-builder-filter-item-component.sortable-chosen {
	outline: 2px dotted var(--action-color-main);
}
.report-builder-filter-item-component .form-field,
.report-builder-filter-item-component .form-field:last-child {
	margin-top: 0;
	margin-bottom: 0;
}
.report-builder-filter-item-component .form-field-input,
.report-builder-filter-item-component .form-control-wrapper {
	max-width: none;
}

.report-builder-filter-item-component .comparator-wrapper .form-field-label,
.report-builder-filter-item-component .value-wrapper .form-field-label {
	font-size: 0.65em;
	text-transform: uppercase;
	margin-bottom: 0;
	background: var(--form-control-background);
	padding: 9px 9px 0;
	border: var(--form-control-border);
	border-bottom: none;
	border-radius: calc(var(--input-border-radisu) * 1.25);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	color: var(--text-color-faded);
}
.report-builder-filter-item-component .comparator-wrapper.error .form-field-label,
.report-builder-filter-item-component .value-wrapper.error .form-field-label {
	border-color: var(--error-background);
	background: hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) + 40%));
	color: hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) + 20%));
}
.report-builder-filter-item-component .comparator-wrapper .form-control,
.report-builder-filter-item-component .value-wrapper .form-control,
.report-builder-filter-item-component .select-input-component.form-control .select-input__control {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.report-builder-filter-item-component .select-input-component.form-control .select-input__control {
	padding-top: 1px;
}
.report-builder-filter-item-component .comparator-wrapper .form-control:not(:read-only):not(:disabled):focus,
.report-builder-filter-item-component .value-wrapper .form-control:not(:read-only):not(:disabled):focus,
.report-builder-filter-item-component .value-wrapper .form-field-input .select-input__control.select-input__control--is-focused,
.report-builder-filter-item-component .react-date-picker.form-control.react-date-picker--open:not(react-date-picker--disabled),
.report-builder-filter-item-component .react-daterange-picker.form-control.react-daterange-picker--open:not(react-daterange-picker--disabled),
.report-builder-filter-item-component .react-datetime-picker.form-control.react-datetime-picker--open:not(react-daterange-picker--disabled),
.report-builder-filter-item-component .select-input-component.form-control .select-input__control.select-input__control--is-focused
{
	border-color: var(--border-color-main);
	border-bottom-color: var(--action-color-main);
	box-shadow: none;
}
.report-builder-filter-item-component .value-wrapper.error .form-control:not(:read-only):not(:disabled):focus,
.report-builder-filter-item-component .value-wrapper.error .form-field-input .select-input__control.select-input__control--is-focused
{
	border-color: var(--error-background);
	border-bottom-color: hsl(var(--error-background-h) var(--error-background-s) calc(var(--error-background-l) - 40%));
	
}

.report-builder-filter-item-component .comparator-wrapper .form-field-label,
.report-builder-filter-item-component .value-wrapper .form-field-label {
	flex: 0 0 auto;
}
.report-builder-filter-item-component .comparator-wrapper .form-field-input,
.report-builder-filter-item-component .value-wrapper .form-field-input {
	flex: 1 1 100%;
	min-height: 0;
}
.report-builder-filter-item-component .comparator-wrapper .form-field-input .form-control,
.report-builder-filter-item-component .value-wrapper .form-field-input .form-control,
.report-builder-filter-item-component .comparator-wrapper .form-field-input .form-control-wrapper,
.report-builder-filter-item-component .value-wrapper .form-field-input .form-control-wrapper 
{
	height: 100%;
}
.select-input.filter-select .select-input__menu-list {
	max-height: calc(((2*5px) + (1rem*1.15)) * 5);
}
.select-input.filter-select .select-input__option {
	padding: 5px 12px;
	font-size: 0.813rem;
}


/* Logical operator ------------------------------------------------------------------------------------------------- */
.report-builder-filter-item-component .logical-operator-wrapper {
	flex: none;
	position: absolute;
	top: -16px;
	left: calc(50% - 28px);
}
.report-builder-filter-item-component .logical-operator-wrapper .react-toggle {
	display: block;
	border-radius: 4px;
	box-shadow: var(--button-shadow);
}
.report-builder-filter-item-component .logical-operator-wrapper .react-toggle .react-toggle-track {
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
	width: 54px;
	height: 27px;
	border-radius: 4px;
	text-transform: uppercase;
}
.report-builder-filter-item-component .logical-operator-wrapper .react-toggle--checked .react-toggle-track {
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.report-builder-filter-item-component .logical-operator-wrapper .react-toggle .react-toggle-thumb {
	width: 15px;
	height: 15px;
	margin-top: 4px;
	margin-left: 4px;
}


/* Drag handle */
.report-builder-filter-item-component .handle-wrapper {
	flex: 0 0 40px;
	font-size: 20px;
	color: var(--icon-action-color);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: move;
}
.report-builder-filter-item-component .handle-wrapper:hover {
	color: var(--icon-action-color-hover);
}
.report-builder-filter-item-component .handle-wrapper .icon:first-of-type {
	margin-right: 2px;
}


/* Filter field ----------------------------------------------------------------------------------------------------- */
.report-builder-filter-item-component .field-wrapper {
	flex: 0 0 250px;
	margin-right: 15px;
	background: var(--form-control-background);
	border: var(--form-control-border);
	border-radius: .25em;
	font-size: var(--input-font-size);
	cursor: pointer;
}
.report-builder-filter-item-component .field-wrapper .dynamic-value-group {
	font-size: 0.875em;
	color: var(--text-color-faded);
	font-weight: normal;
	padding: 10px 15px 0;
	border-bottom: none;
	min-height: 0;
}
.report-builder-filter-item-component .field-wrapper .dynamic-value {
	font-size: 1em;
	font-weight: 600;
	padding: 5px 15px 10px;
	line-height: 1;
	min-height: 0;
	display: flex;
	flex-flow: row nowrap;
}


/* Comparator ------------------------------------------------------------------------------------------------------- */
.report-builder-filter-item-component .comparator-wrapper {
	flex: 0 0 250px;
	margin-right: 15px;
	display: flex;
	flex-flow: column nowrap;
}


/* Filter value ----------------------------------------------------------------------------------------------------- */
.report-builder-filter-item-component .value-wrapper {
	flex: 1 1 100%;
	min-width: 250px;
	margin-right: 0;
}


/* Remove button ---------------------------------------------------------------------------------------------------- */
.report-builder-filter-item-component .remove-wrapper {
	position: absolute;
	top: calc(50% - 16px);
	right: -18px;
	margin-right: 0;
}
.report-builder-filter-item-component .remove-wrapper .button-component {
	margin: 0;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-color: hsl(var(--error-background-h), calc(var(--error-background-s) - 15%), calc(var(--error-background-l) + 15%));
	color: var(--error-text);
	border: none;
	box-shadow: var(--button-shadow);
	opacity: 0 !important;
}
.report-builder-filter-item-component:hover .remove-wrapper .button-component {
	opacity: 1 !important;
	background-color: var(--error-background);
}
.report-builder-filter-item-component:hover .remove-wrapper .button-component:hover {
	background-color: hsl(var(--error-background-h), var(--error-background-s), calc(var(--error-background-l) - 15%));
	color: var(--error-text);
}