body.skin-light .cookie-settings-dialog-component.default-style {
	--section-border-color: #b5b5b5;
}
body.skin-dark .cookie-settings-dialog-component.default-style {
	--section-border-color: #252525;
}

.cookie-settings-dialog .dialog-content-component,
.cookie-settings-dialog .dialog-content-component .content {
	padding: 0;
}
.cookie-settings-dialog .dialog-content-component {
	height: 100vh;
}
.cookie-settings-dialog .dialog-content-component .content {
	height: 100%;
}
.cookie-settings-dialog .dialog-content-component .content:after {
	display: none;
}

.cookie-settings-dialog-component.default-style {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	height: 100%;
}

.cookie-settings-dialog-component.default-style > nav {
	flex: 0 0 517px;
	background: var(--background-color-main);
	padding: 0 65px;
	height: 100%;
	overflow: auto;
}
.cookie-settings-dialog-component.default-style .cookie-img {
	display: block;
	margin: 60px auto 100px;
}
.cookie-settings-dialog-component.default-style > nav .button-component {
	width: 100%;
	margin-bottom: 25px;
	background: var(--background-color-secondary);
	color: var(--action-color-main);
	padding: 1.25em 0;
	letter-spacing: 0.025em;
	text-align: left;
	display: flex;
	align-items: center;
}
.cookie-settings-dialog-component.default-style > nav .button-component.current {
	background: none;
	color: var(--text-color-main);
	border-color: var(--background-color-secondary);
}

.cookie-settings-dialog-component.default-style > nav .button-component .button-component-icon {
	float: left;
	font-size: 24px;
	margin: 0 50px 0 25px;
}

.cookie-settings-dialog-component.default-style > section {
	background: var(--background-color-secondary);
	color: var(--text-color-main);
	font-size: 20px;
	font-weight: 500;
	display: flex;
	flex: 1 1 auto;
	flex-flow: column nowrap;
	justify-content: space-between;
	padding: 60px 60px 35px;
}
.cookie-settings-dialog-component.default-style > section > .tab-title {
	text-align: left;
	font-size: 1.6em;
	line-height: 1.2;
	font-weight: 700;
	padding: 0;
	margin-bottom: 25px;
	flex: 0 0 auto;
}
.cookie-settings-dialog-component.default-style > section > .tab-content {
	overflow: auto;
	flex: 1 1 auto;
}
.cookie-settings-dialog-component.default-style > section > .tab-content h2 {
	font-size: 1em;
	font-weight: 700;
	color: var(--text-color-main);
}
.cookie-settings-dialog-component.default-style > section > .tab-content h2:first-child {
	margin-top: 0;
}
.cookie-settings-dialog-component.default-style > section > .tab-content .underline {
	text-decoration: underline;
}

.cookie-settings-dialog-component.default-style > section .toggle-cookie-group {
	flex-flow: row-reverse nowrap;
	justify-content: flex-end;
	min-height: 0;
	margin-top: 30px;
}
.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .form-field-input {
	flex: 0 0 auto;
}
.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .react-toggle-track {
	width: 61px;
	height: 35px;
}
.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .react-toggle-thumb {
	width: 31px;
	height: 31px;
}
.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .toggle-input-component {
	margin-left: 0;
}
.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .form-field-label {
	padding: 0 0 0 1em;
	border: none;
	flex: 0 0 auto;
	font-size: 0.9em;
	font-weight: 700;
	cursor: pointer;
}
.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .form-field-label.on {
	color: var(--action-color-main);
}
.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .form-field-label.off {
	color: var(--section-border-color);
}
.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .toggle-input-component:not(.react-toggle--checked) .react-toggle-track {
	background: var(--section-border-color);
}

.cookie-settings-dialog-component.default-style > section .tab-actions {
	padding-top: 35px;
	margin-top: 45px;
	border-top: 2px solid var(--section-border-color);
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	flex: 0 0 auto;
}
.cookie-settings-dialog-component.default-style > section .tab-actions .button-component {
	font-size: 0.7em;
}

@media screen and (max-width: 1280px) {
	.cookie-settings-dialog-component.default-style > nav {
		padding: 0 45px;
		flex-basis: 350px;
	}
	.cookie-settings-dialog-component.default-style > nav .button-component .button-component-icon {
		margin-right: 25px;
	}

	.cookie-settings-dialog-component.default-style > section {
		font-size: 16px;
	}
}

@media screen and (max-width: 1023px) {
	.cookie-settings-dialog-component.default-style {
		flex-flow: column nowrap;
	}
	
	.cookie-settings-dialog-component.default-style > nav {
		flex: 0 0 140px;
		text-align: center;
		overflow: hidden;
		padding-left: 15px;
		padding-right: 15px;
	}
	.cookie-settings-dialog-component.default-style .cookie-img {
		margin: 30px auto 26.2px;
		height: 36.8px;
	}
	.cookie-settings-dialog-component.default-style > nav .button-component {
		display: inline-block;
		width: 37px;
		height: 37px;
		margin-bottom: 0;
		margin-right: 14px;
		padding: 0;
		text-align: center;
		justify-content: center;
		border-radius: 4px;
		border-width: 1px;
	}
	.cookie-settings-dialog-component.default-style > nav .button-component:last-child {
		margin-right: 0;
	}
	.cookie-settings-dialog-component.default-style > nav .button-component .button-component-icon {
		float: none;
		margin: 0;
	}
	.cookie-settings-dialog-component.default-style > nav .button-component > span {
		display: none;
	}

	.cookie-settings-dialog-component.default-style > section {
		min-height: 0;
		font-size: 14px;
		padding: 25px 15px;
	}
	.cookie-settings-dialog-component.default-style > section > .tab-title {
		font-size: 1.143em;
	}
	.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .form-field-label {
		font-size: 1em;
	}
	.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .react-toggle-track {
		width: 54px;
		height: 28px;
	}
	.cookie-settings-dialog-component.default-style > section .toggle-cookie-group .react-toggle-thumb {
		width: 24px;
		height: 24px;
	}
	.cookie-settings-dialog-component.default-style > section .tab-actions {
		padding-top: 21px;
		margin-top: 31px;
		border-top-width: 1px;
	}
	.cookie-settings-dialog-component.default-style > section .tab-actions .button-component {
		font-size: 0.857em;
		font-weight: 400;
		padding: 6px 5px 5px;
		letter-spacing: -0.01em;
	}
}