/*noinspection CssInvalidAtRule*/
@import-normalize;

html, body {
	overflow: hidden;
}

body {
	font: var(--font-main);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--background-color-main);
	margin: 0;
}

body, body * {
	box-sizing: border-box;
}

code {
	font: var(--font-main-mono);
}

a {
	color: var(--action-color-main);
	transition: var(--transition-hover-in);
}
a:hover {
	opacity: 0.75;
	transition: var(--transition-hover-out);
}

h1 {
	font-weight: 200;
}

#root {
	height: 100vh;
}