.wrapper {
	
}

.wrapper .header {
	padding-left: 40px;
	padding-right: 40px;
}

.wrapper .content {
	padding: 0 40px 40px;
}