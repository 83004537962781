.wrapper {

}
.wrapper .header {
	padding-left: 40px;
	padding-right: 40px;
}
.wrapper .content {
	padding: 0 40px 40px;
}

.successWrapper {
	width: 90%;
	max-width: 900px;
}

.successWrapper .appName {
	text-align: center;
	font-weight: 600;
	font-size: 0.5em;
	margin-top: -10px;
}

.successWrapper .notice {
	text-align: center;
	padding: 10px 0 20px;
}
.successWrapper .notice > i, 
.successWrapper .notice > p {
	margin: 0;
}