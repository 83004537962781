.wrapper {
	background: var(--card-background-fade);
	padding: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.wrapper.withTitle {
	padding-top: 10px;
}
.wrapper.isMulti {
	padding-bottom: 0;
}

.wrapper .form {
	display: flex;
	flex-flow: row nowrap;
}
.wrapper .form .formContent {
	flex: 1 1 100%;
}
.wrapper .form .formSidebar {
	flex: 0 0 40px;
	margin-left: 20px;
}

.wrapper .insertBtn {
	width: 100%;
	height: 100%;
	margin: 0;
}
.wrapper.isMulti .insertBtn {
	height: calc(100% - 32px);
}

.wrapper .addBtn {
	width: 100%;
	height: 32px;
	margin: 0;
}

.wrapper .title {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1;
	padding-top: 5px;
	background: var(--card-background-fade);
	display: flex;
	flex-flow: row nowrap;
}
.wrapper .title .separator {
	flex: 1 1 100%;
	font-size: 1em;
	border: none;
	margin-bottom: 0;
}

.wrapper .form .row {
	margin-bottom: 0;
	margin-top: -1px;
}
.wrapper .form .rowInput {
	display: flex;
	flex-flow: row nowrap;
	max-width: none;
}
.wrapper .form .rowInput .value {
	flex: 1 1 100%;
	min-width: 0;
}
.wrapper .form .rowInput .value > div {
	border-radius: 0;
}
.wrapper .form .row:first-of-type .rowInput .value > div {
	border-top-left-radius: var(--input-border-radisu);
}
.wrapper .form .row:last-of-type .rowInput .value > div {
	border-bottom-left-radius: var(--input-border-radisu);
}
.wrapper .form .rowInput .value > div {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 0;
}
.wrapper .form .rowInput .modifier {
	flex: 0 0 100px;
	min-width: 100px;
}
.wrapper .form .rowInput .modifier input {
	border-radius: 0;
	border-right: 0;
}
.wrapper .form .rowInput .unit {
	flex: 0 0 150px;
	min-width: 150px;
	border-radius: 0;
}
.wrapper .form .rowInput .unit > div {
	border-radius: 0;
	border-right: 0;
}
.wrapper .form .rowInput .deleteBtn {
	flex: 0 0 35px;
	min-width: 35px;
	height: 100%;
	border-radius: 0;
	margin: 0;
}
.wrapper .form .row:first-of-type .rowInput .deleteBtn {
	border-top-right-radius: var(--input-border-radisu);
}
.wrapper .form .row:last-of-type .rowInput .deleteBtn {
	border-bottom-right-radius: var(--input-border-radisu);
}