.wrapper {
	width: 90%;
	max-width: 1440px;
	padding: 40px 0;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.wrapper .card {
	max-width: 60em;
}

.wrapper .features {
	
}

.wrapper .featuresTitle {
	color: var(--card-background);
	text-align: center;
	font-size: 1.75rem;
	word-spacing: 0.25em;
	margin-bottom: 0.5em;
	margin-top: 2em;
	text-transform: uppercase;
	text-shadow: var(--features-title-shadow);
}