/*
	Default SimpleStaticSearch component style
	@see 'styleName' prop of the component.
*/

body.skin-light .simple-static-search-component.default-style {
	--inner-box-shadow:
		inset 0px 11px 8px -10px rgba(var(--card-shadow-rgb), 0.15),
		inset 0px -11px 8px -10px rgba(var(--card-shadow-rgb), 0.15);
}

body.skin-dark .simple-static-search-component.default-style {
	--inner-box-shadow:
		inset 0px 11px 8px -10px rgba(var(--card-shadow-rgb), 0.45),
		inset 0px -11px 8px -10px rgba(var(--card-shadow-rgb), 0.45);
}

.simple-static-search-component.default-style {
	--collapse-button-width: 45px;
	--search-button-size: 50px;
	
	background: var(--card-background);
	color: var(--card-text);
	border: var(--card-border);
}

.simple-static-search-component.default-style .title {
	padding: 10px calc(15px + var(--collapse-button-width)) 10px 15px;
	font-size: 18px;
	font-weight: 600;
	border-bottom: var(--card-border);
}
.simple-static-search-component.default-style .title:after {
	color: var(--icon-action-color);
}
.simple-static-search-component.default-style .title:hover:after {
	color: var(--icon-action-color-hover);
}

.simple-static-search-component.default-style .content-wrapper {
	background: var(--background-color-secondary);
	box-shadow: var(--inner-box-shadow)
}

.simple-static-search-component.default-style .toolbar {
	margin: -15px -15px 0 -15px;
	border-width: 0 1px 1px 0;
	border-radius: 0;
}

.simple-static-search-component.default-style .options .form-wrapper {
	padding: 15px calc(15px + var(--search-button-size)) 15px 15px;
}

.simple-static-search-component.default-style .actions {
	position: absolute;
	top: -1px;
	right: 0;
	width: var(--search-button-size);
	height: calc(100% + 1px);
}

.simple-static-search-component.default-style .actions .search-button {
	width: 100%;
	height: 100%;
	border-radius: 0;
	font-size: calc(var(--collapse-button-width) / 2);
	padding: 0;
}

@media screen and (max-width: 640px) {
	.simple-static-search-component.default-style .options {
		padding-bottom: 2rem;
	}

	.simple-static-search-component.default-style .toolbar {
		margin: -1px -20px 0 -20px;
		border-width: 0 0 1px 0;
	}
	
	.simple-static-search-component.default-style .options .form-wrapper {
		padding: 1px 20px 0 20px;
	}
	
	.simple-static-search-component.default-style .actions {
		position: relative;
		top: auto;
		right: auto;
		height: var(--search-button-size);
		width: 100%;
		padding: 0 20px;
	}
}