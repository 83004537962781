:root {
	/* Color palette */
	--palette-red: #e63946;
	--palette-red-h: 355;
	--palette-red-s: 78%;
	--palette-red-l: 56%;
	
	--palette-orange: #FFB94D;
	--palette-orange-h: 36;
	--palette-orange-s: 100%;
	--palette-orange-l: 65%;

	--palette-green: #13b294;
	--palette-green-h: 169;
	--palette-green-s: 81%;
	--palette-green-l: 39%;
	
	--palette-sky: #5CA8FF;
	--palette-sky-h: 212;
	--palette-sky-s: 100%;
	--palette-sky-l: 68%;
	
	--palette-blue: #006E90;
	--palette-blue-h: 194;
	--palette-blue-s: 100%;
	--palette-blue-l: 28%;
	
	--palette-reporting-green: #13b294;
	--palette-reporting-green-h: 169;
	--palette-reporting-green-s: 81%;
	--palette-reporting-green-l: 39%;
	
	--palette-triple-right: #8A7ED3;
	--palette-triple-right-h: 248;
	--palette-triple-right-s: 49%;
	--palette-triple-right-l: 66%;

	--palette-leadit-red: #EC1B23;
	--palette-leadit-red-h: 358;
	--palette-leadit-red-s: 85%;
	--palette-leadit-red-l: 52%;

	--palette-leadit-red2: #FF4A4F;
	--palette-leadit-red2-h: 358;
	--palette-leadit-red2-s: 100%;
	--palette-leadit-red2-l: 65%;
	
	/* Colors */
	--info-background: var(--palette-sky);
	--info-background-h: var(--palette-sky-h);
	--info-background-s: var(--palette-sky-s);
	--info-background-l: var(--palette-sky-l);
	--info-text: #fff;

	--help-background: var(--palette-sky);
	--help-background-h: var(--palette-sky-h);
	--help-background-s: var(--palette-sky-s);
	--help-background-l: var(--palette-sky-l);
	--help-text: #fff;
	
	--warning-background: var(--palette-orange);
	--warning-background-h: var(--palette-orange-h);
	--warning-background-s: var(--palette-orange-s);
	--warning-background-l: var(--palette-orange-l);
	--warning-text: #fff;
	
	--error-background: var(--palette-red);
	--error-background-h: var(--palette-red-h);
	--error-background-s: var(--palette-red-s);
	--error-background-l: var(--palette-red-l);
	--error-text: #fff;
	
	--success-background: var(--palette-green);
	--success-background-h: var(--palette-green-h);
	--success-background-s: var(--palette-green-s);
	--success-background-l: var(--palette-green-l);
	--success-text: #fff;
	
	/* Main */
	--font-main: 16px/1.4 MainSans, "open_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	--font-main-mono: 16px/1.4 MainMono, Consolas, Menlo, Monaco, Consolas, 'Courier New', monospace;
	--font-main-input: 14px/1.2 Helvetica, Arial, sans-serif;

	/* Layout */
	--z-index-messages: 1000;
	--z-index-loading: 900;
	--z-index-overlay: 800;
	--z-index-dialog: 700;
	--z-index-popup: 600;
	--padding-section: 40px;
	--header-size: 60px;
	
	/* Transitions */
	--transition-hover-in: all 0.15s ease-in;
	--transition-hover-out: all 0.05s ease-out;
	--transition-background-color-in: background-color 0.15s ease-in;
	--transition-background-color-out: background-color 0.05s ease-out;

	/* Overlays */
	--blur-overlay: 5px;

	/* Dropdown */
	--dropdown-min-width: 200px;
	--dropdown-grid-width: 320px;
	--dropdown-arrow-size: 7px;
	--dropdown-border-radius: 6px;
	--dropdown-item-border-radius: 4px;
	--dropdown-content-padding: 10px;
	--dropdown-grid-content-padding: 10px;

	/* Cards */
	--card-border-radius: 6px;

	/* Buttons */
	--button-border-radius: 4px;

	/* Dialogs */
	--dialog-border-radius: 6px;
	
	/* Tabs */
	--tab-active-top-borde-width: 3px;
	
	/* Popups */
	--popup-tab-active-top-borde-width: 3px;
	--popup-content-padding: 30px;
	
	/* Pagination */
	--pagination-button-width: 2em;
	--pagination-button-height: 1.8em;

	/* Table */
	--table-font-size: 14px;

	/* Forms */
	--form-control-disabled-opacity: 0.6;
	--form-field-max-width: 700px;
	--form-filed-small-width: 200px;
	--form-label-width: 230px;
}

body, body.skin-light {
	/* Hover */
	--hover-intensity: 10%;
	
	/* Colors */
	--default-background: #666;
	--default-background-h: 0;
	--default-background-s: 0%;
	--default-background-l: 40%;
	--default-background-hover: hsl(var(--default-background-h), var(--default-background-s), calc(var(--default-background-l) - var(--hover-intensity)));
	--default-text: #fff;
	
	--info-background-hover: hsl(var(--info-background-h), var(--info-background-s), calc(var(--info-background-l) - var(--hover-intensity)));
	--help-background-hover: hsl(var(--help-background-h), var(--help-background-s), calc(var(--help-background-l) - var(--hover-intensity)));
	--warning-background-hover: hsl(var(--warning-background-h), var(--warning-background-s), calc(var(--warning-background-l) - var(--hover-intensity)));
	--error-background-hover: hsl(var(--error-background-h), var(--error-background-s), calc(var(--error-background-l) - var(--hover-intensity)));
	--success-background-hover: hsl(var(--success-background-h), var(--success-background-s), calc(var(--success-background-l) - var(--hover-intensity)));
	
	/* Main */
	--background-color-main: #f3f3f3;
	--background-color-secondary: #f7f7f7;
	--text-color-main: #333;
	--text-color-subtle: #666;
	--text-color-faded: #888;
	--action-color-main: var(--palette-reporting-green);
	--action-color-contrast-main: #fff;
	--action-color-faded: hsla(var(--palette-reporting-green-h), calc(var(--palette-reporting-green-s) + 100%), var(--palette-reporting-green-l), 0.1);
	--action-color-hover: hsl(var(--palette-reporting-green-h), var(--palette-reporting-green-s), calc(var(--palette-reporting-green-l) - var(--hover-intensity)));
	--border-color-main: #ccc;
	--icon-action-color: #999;
	--icon-action-color-hover: #333;
	--transparent-contrast: rgba(0, 0, 0, 0.3);
	--transparent-contrast-subtle: rgba(0, 0, 0, 0.1);
	--hover-clear-box-shadow: inset 0 0 0 999em transparent;
	--hover-box-shadow: inset 0 0 0 999em var(--transparent-contrast-subtle);
	
	/* Spinner */
	--spinner-color: rgba(0, 0, 0, 0.4);
	
	/* Layout */
	--sidebar-bkg-color: #fff;
	--sidebar-hover-bkg-color: rgba(0, 0, 0, 0.05);
	--sidebar-selected-bkg-color: var(--action-color-faded);
	--sidebar-text-color: var(--text-color-main);
	--sidebar-hover-text-color: var(--sidebar-text-color);
	--sidebar-selected-text-color: var(--action-color-main);
	--sidebar-icon-color: var(--icon-action-color);
	--sidebar-sub-bkg-color: #f5f5f5;
	--sidebar-sub-hover-bkg-color: var(--sidebar-hover-bkg-color);
	--sidebar-sub-selected-bkg-color: var(--sidebar-selected-bkg-color);
	--sidebar-sub-text-color: var(--sidebar-text-color);
	--sidebar-sub-hover-text-color: var(--sidebar-hover-text-color);
	--sidebar-sub-selected-text-color: var(--sidebar-selected-text-color);
	--sidebar-border-color: #d5d5d5;
	--sidebar-border: 1px solid var(--sidebar-border-color);
	--sidebar-selected-border-color: transparent;
	--sidebar-sub-selected-border-color: var(--sidebar-selected-border-color);
	--sidebar-box-shadow-rgba: rgba(0, 0, 0, 0.1);
	--sidebar-box-shadow: 0 0 4px var(--sidebar-box-shadow-rgba);

	--header-bkg-image: none;
	--header-bkg-color: #fff;
	--header-bkg-color-hover: #f3f3f3;
	--header-border-color: rgba(0, 0, 0, 0.15);
	--header-border: 1px solid var(--header-border-color);
	--header-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
	--header-text-color: #333;
	
	--page-title-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
	--page-title-border-color: #ccc;

	/* Overlays */
	--background-overlay-transparent: rgba(255, 255, 255, 0.6);
	--background-overlay-opaque: var(--background-color-main);
	--text-color-overlay: #666;

	/* Cards */
	--card-background: #fff;
	--card-background-fade: #f3f3f3;
	--card-border: 1px solid #ccc;
	--card-shadow-rgb: 0, 0, 0;
	--card-shadow: 0 2px 4px 0px rgba(var(--card-shadow-rgb), 0.2);
	--card-text: #333;

	/* Toolbar */
	--toolbar-text-color: #666;
	--toolbar-backround: linear-gradient(to bottom, #f5f5f5, #e5e5e5);
	--toolbar-backround-hover: rgba(255, 255, 255, 0.75);
	--toolbar-border-color: #bbb;
	--toolbar-separator-border-color: rgba(0, 0, 0, 0.2);
	--toolbar-separator-outline-color: rgba(255, 255, 255, 0.8);
	
	/* Dropdown */
	--dropdown-background: #fff;
	--dropdown-background-hover: #f0f0f0;
	--dropdown-border: 1px solid #ccc;
	--dropdown-shadow-rgb: 0, 0, 0;
	--dropdown-shadow: 0 2px 4px 0px rgba(var(--card-shadow-rgb), 0.2);
	--dropdown-text: #333;
	--dropdown-text-faded: #999;

	/* Buttons */
	--button-default-background: #666;
	--button-default-background-hover: #444;
	--button-default-text: #fff;
	--button-subtle-background: #ccc;
	--button-subtle-background-hover: #bbb;
	--button-subtle-solid-text: var(--button-default-background);
	--button-subtle-solid-text-hover: var(--button-default-background-hover);
	--button-none-hover-background-hover: #ddd;
	--button-shadow-rgb: 0, 0, 0;
	--button-shadow: 0 4px 6px 0px rgba(var(--button-shadow-rgb), 0.3);
	--button-focus-outline-color: #333;

	/* Dialogs */
	--dialog-background: #fff;
	--dialog-border: 1px solid #ccc;
	--dialog-text: #333;
	--dialog-close-text: #666;
	--dialog-close-text-hover: #333;
	--dialog-box-shadow: 3px 3px 20px #333;
	
	/* Tabs */
	--tab-border: 1px solid #ccc;
	--tab-background: #fbfbfb;
	--tab-text: #666;
	--tab-active-top-borde-color: #333;
	--tab-active-background: #f3f3f3;
	--tab-active-text: #333;
	--tab-hover-background: #fff;
	--tab-hover-text: #333;
	
	/* Popups */
	--popup-background: #fff;
	--popup-background-fade: #f7f7f7;
	--popup-border: 1px solid #ccc;
	--popup-text: #333;
	--popup-close-text: #666;
	--popup-close-text-hover: #333;
	--popup-box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.25);
	--popup-tab-text: #444;
	--popup-tab-text-active: #333;
	--popup-tab-active-top-borde-color: #333;
	--popup-tab-hover-background: #fbfbfb;
	--popup-tab-hover-text-color: #333;
	--popup-tab-content-toolbar-border: 0 4px 2px -2px rgba(0, 0, 0, .1);
	
	/* Table */
	--table-background: #fff;
	--table-border-color: rgba(0, 0, 0, 0.2);
	--table-head-background: #fff;
	--table-head-text-color: #333;
	--table-row-even-background: rgba(255, 255, 255, 0.05);
	--table-row-odd-background: rgba(0, 0, 0, 0.05);
	--table-row-text-color: #666;
	--table-row-highlight-background: var(--action-color-faded);
	
	/* Forms */
	--form-control-background: #fff;
	--form-control-text: #333;
	--form-control-border: 1px solid var(--border-color-main);
	--form-control-label-background: #f5f5f5;
	--form-control-label-text: #888;
	--form-control-placeholder-text: #999;
	
	/* Calendar */
	--calendar-background: #fff;
	--calendar-selected-background: var(--action-color-main);
	--calendar-now-background: #eee;
	--calendar-hover: rgba(0, 0, 0, 0.25);
	--calendar-border: 1px solid var(--border-color-main);
	--calendar-shadow-rgb: 0, 0, 0;
	--calendar-shadow: 0 2px 4px 0px rgba(var(--card-shadow-rgb), 0.2);
	--calendar-text: #333;
	--calendar-text-weekend: var(--palette-red);
	
	/* Notice */
	--notice-background: rgba(0, 0, 0, 0.05);
	--notice-text: rgba(0, 0, 0, 0.65);
	
	/* Tag */
	--tag-background: rgba(0, 0, 0, 0.075);
}

body.skin-dark {
	/* Hover */
	--hover-intensity: 5%;
	
	/* Colors */
	--default-background: #444;
	--default-background-h: 0;
	--default-background-s: 0%;
	--default-background-l: 27%;
	--default-background-hover: hsl(var(--default-background-h), var(--default-background-s), calc(var(--default-background-l) + var(--hover-intensity)));
	--default-text: #fff;
	
	--info-background-hover: hsl(var(--info-background-h), var(--info-background-s), calc(var(--info-background-l) + var(--hover-intensity)));
	--help-background-hover: hsl(var(--help-background-h), var(--help-background-s), calc(var(--help-background-l) + var(--hover-intensity)));
	--warning-background-hover: hsl(var(--warning-background-h), var(--warning-background-s), calc(var(--warning-background-l) + var(--hover-intensity)));
	--error-background-hover: hsl(var(--error-background-h), var(--error-background-s), calc(var(--error-background-l) + var(--hover-intensity)));
	--success-background-hover: hsl(var(--success-background-h), var(--success-background-s), calc(var(--success-background-l) + var(--hover-intensity)));
	
	/* Main */
	--background-color-main: #111;
	--background-color-secondary: #292929;
	--text-color-main: #999;
	--text-color-subtle: #888;
	--text-color-faded: #666;
	--action-color-main: var(--palette-reporting-green);
	--action-color-contrast-main: #fff;
	--action-color-faded: hsla(var(--palette-reporting-green-h), calc(var(--palette-reporting-green-s) - 1%), var(--palette-reporting-green-l), 0.15);
	--action-color-hover: hsl(var(--palette-reporting-green-h), var(--palette-reporting-green-s), calc(var(--palette-reporting-green-l) + var(--hover-intensity)));
	--border-color-main: #444;
	--icon-action-color: #666;
	--icon-action-color-hover: #ccc;
	--transparent-contrast: rgba(255, 255, 255, 0.3);
	--transparent-contrast-subtle: rgba(255, 255, 255, 0.15);
	--hover-clear-box-shadow: inset 0 0 0 999em transparent;
	--hover-box-shadow: inset 0 0 0 999em var(--transparent-contrast-subtle);

	/* Spinner */
	--spinner-color: rgba(255, 255, 255, 0.4);

	/* Layout */
	--sidebar-bkg-color: #333;
	--sidebar-hover-bkg-color: rgba(0, 0, 0, 0.25);
	--sidebar-selected-bkg-color: var(--action-color-faded);
	--sidebar-text-color: var(--text-color-main);
	--sidebar-hover-text-color: var(--sidebar-text-color);
	--sidebar-selected-text-color: var(--action-color-main);
	--sidebar-icon-color: var(--icon-action-color);
	--sidebar-sub-bkg-color: #272727;
	--sidebar-sub-hover-bkg-color: var(--sidebar-hover-bkg-color);
	--sidebar-sub-selected-bkg-color: var(--sidebar-selected-bkg-color);
	--sidebar-sub-text-color: var(--sidebar-text-color);
	--sidebar-sub-hover-text-color: var(--sidebar-hover-text-color);
	--sidebar-sub-selected-text-color: var(--sidebar-selected-text-color);
	--sidebar-border-color: #222;
	--sidebar-border: 1px solid var(--sidebar-border-color);
	--sidebar-selected-border-color: transparent;
	--sidebar-sub-selected-border-color: var(--sidebar-selected-border-color);
	--sidebar-box-shadow-rgba: rgba(0, 0, 0, 1);
	--sidebar-box-shadow: 0 0 4px var(--sidebar-box-shadow-rgba);

	--header-bkg-image: none;
	--header-bkg-color: #333;
	--header-bkg-color-hover: #292929;
	--header-border-color: rgba(0, 0, 0, 0.3);
	--header-border: 1px solid var(--header-border-color);
	--header-box-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
	--header-text-color: #aaa;

	--page-title-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.85);
	--page-title-border-color: #444;

	/* Overlays */
	--background-overlay-transparent: rgba(0, 0, 0, 0.5);
	--background-overlay-opaque: var(--background-color-main);
	--text-color-overlay: #999;

	/* Cards */
	--card-background: #333;
	--card-background-fade: #282828;
	--card-border: 1px solid rgba(255, 255, 255, 0.15);
	--card-shadow-rgb: 0, 0, 0;
	--card-shadow: 0px 2px 4px 0px rgba(var(--card-shadow-rgb), 1);
	--card-text: #ccc;

	/* Toolbar */
	--toolbar-text-color: #ccc;
	--toolbar-backround: linear-gradient(to bottom, #333, #242424);
	--toolbar-backround-hover: rgba(255, 255, 255, 0.15);
	--toolbar-border-color: #1C1C1C;
	--toolbar-separator-border-color: rgba(0, 0, 0, 0.4);
	--toolbar-separator-outline-color: rgba(255, 255, 255, 0.1);

	/* Dropdown */
	--dropdown-background: #333;
	--dropdown-background-hover: #444;
	--dropdown-border: 1px solid rgba(255, 255, 255, 0.15);
	--dropdown-shadow-rgb: 0, 0, 0;
	--dropdown-shadow: 0px 2px 4px 0px rgba(var(--card-shadow-rgb), 1);
	--dropdown-text: #ccc;
	--dropdown-text-faded: #777;

	/* Buttons */
	--button-default-background: #707070;
	--button-default-background-hover: #909090;
	--button-default-text: #fff;
	--button-subtle-background: #444;
	--button-subtle-background-hover: #555;
	--button-subtle-solid-text: var(--button-default-text);
	--button-subtle-solid-text-hover: var(--button-default-text);
	--button-none-hover-background-hover: #222;
	--button-shadow-rgb: 0, 0, 0;
	--button-shadow: 0 4px 6px 0px rgba(var(--button-shadow-rgb), 0.5);
	--button-focus-outline-color: #ccc;

	/* Dialogs */
	--dialog-background: #333;
	--dialog-border: 1px solid rgba(255, 255, 255, 0.15);
	--dialog-text: #ccc;
	--dialog-close-text: #666;
	--dialog-close-text-hover: #ccc;
	--dialog-box-shadow: 3px 3px 20px 10px #000, inset 0 0 0 1px rgba(255, 255, 255, 0.15);

	/* Tabs */
	--tab-border: 1px solid rgba(255, 255, 255, 0.1);
	--tab-background: #292929;
	--tab-text: #999;
	--tab-active-top-borde-color: #999;
	--tab-active-background: #111;
	--tab-active-text: #ccc;
	--tab-hover-background: #303030;
	--tab-hover-text: #aaa;
	
	/* Popups */
	--popup-background: #333;
	--popup-background-fade: #292929;
	--popup-border: 1px solid rgba(255, 255, 255, 0.1);
	--popup-text: #ccc;
	--popup-close-text: #666;
	--popup-close-text-hover: #ccc;
	--popup-box-shadow: 0 0 30px 4px #000;
	--popup-tab-text: #aaa;
	--popup-tab-text-active: #ccc;
	--popup-tab-active-top-borde-color: #999;
	--popup-tab-hover-background: #303030;
	--popup-tab-hover-text-color: #aaa;
	--popup-tab-content-toolbar-border: 0 4px 2px -2px rgba(0, 0, 0, .25);

	/* Table */
	--table-background: #222;
	--table-border-color: rgba(255, 255, 255, 0.15);
	--table-head-background: #333;
	--table-head-text-color: #ccc;
	--table-row-even-background: rgba(255, 255, 255, 0.05);
	--table-row-odd-background: rgba(0, 0, 0, 0.05);
	--table-row-text-color: #aaa;
	--table-row-highlight-background: var(--action-color-faded);

	/* Forms */
	--form-control-background: #222;
	--form-control-text: #eee;
	--form-control-border: 1px solid var(--border-color-main);
	--form-control-label-background: #141414;
	--form-control-label-text: #666;
	--form-control-placeholder-text: #777;

	/* Calendar */
	--calendar-background: #222;
	--calendar-selected-background: var(--action-color-main);
	--calendar-now-background: #333;
	--calendar-hover: rgba(255, 255, 255, 0.15);
	--calendar-border: 1px solid var(--border-color-main);
	--calendar-shadow-rgb: 0, 0, 0;
	--calendar-shadow: 0 2px 4px 0px rgba(var(--card-shadow-rgb), 1);
	--calendar-text: #eee;
	--calendar-text-weekend: var(--palette-red);

	/* Notice */
	--notice-background: rgba(255, 255, 255, 0.05);
	--notice-text: rgba(255, 255, 255, 0.65);

	/* Tag */
	--tag-background: rgba(255, 255, 255, 0.075);
}