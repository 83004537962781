.withIcon {
	padding-right: calc(1rem + 1em);
}

.clockIcon {
	position: absolute;
	top: 0;
	right: calc(.5rem + 6px);
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	color: var(--icon-action-color);
	z-index: 1;
}