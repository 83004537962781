/* Layout backgrounds ----------------------------------------------------------------------------------------------- */
.layout.background-image {
	background-image: url("../images/bkg.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.layout.background-image-error {
	background-image: url("../images/bkg_error.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}


/* Default layout alternative styles */
.layout-default.flex .layout-content {
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: calc(100% - var(--header-size));
	display: flex;
	flex-flow: column nowrap;
}
.layout-default.flex .layout-content:before,
.layout-default.flex .layout-content > .page-title {
	flex: 0 0 auto;
}
.layout-default.flex .layout-content > .page-content {
	flex: 1 1 100%;
	display: flex;
	flex-flow: column nowrap;
	min-height: 0;
}
@media screen and (max-width: 768px) {
	.layout-default.flex .layout-content::before,
	.layout-default.flex .layout-content > .page-title,
	.layout-default.flex .layout-content > .page-content {
		display: block;
		flex: 0 0 auto;
	}
}


/* Layout other ----------------------------------------------------------------------------------------------------- */
.simple-page-description {
	margin-top: -15px; /* Negative since page title that ius right above it has a bottom margin */
	margin-bottom: var(--padding-section);
	color: var(--text-color-faded);
	max-width: 800px;
	line-height: 1.2;
	font-weight: 150;
}
.card.page-description {
	margin-bottom: 2.5em;
	font-size: 1.125rem;
	font-weight: 300;
	line-height: 1.3;
	border: none;
	border-left: 3px solid var(--palette-sky);
}
.card.page-description .card-content {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	color: var(--text-color-subtle);
	line-height: 1.25;
}
.card.page-description .card-content .card-icon {
	flex: 0 0 32px;
	font-size: 32px;
	margin-right: 15px;
	text-align: center;
	color: var(--text-color-faded);
	display: flex;
	justify-content: center;
	align-items: center;
}
.card.page-description .card-content strong {
	font-weight: 600;
}


/* Main sidebar menu */
#layout-main-sidebar .main-menu {
	padding: 0 var(--sidebar-padding-x) var(--sidebar-padding-y);
}
#layout-main-sidebar .main-menu a,
#layout-main-sidebar .main-menu p {
	font-size: 14px;
	font-weight: 600;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	text-decoration: none;
	padding: .75em;
	box-shadow: inset 0 0 0 999em transparent;
	transition: var(--transition-hover-out);
	color: var(--sidebar-text-color);
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	line-height: 1;
	border-radius: var(--sidebar-item-border-radius);
}
#layout-main-sidebar .main-menu a.active {
	background: var(--sidebar-selected-bkg-color);
	border-color: var(--sidebar-selected-border-color);
	color: var(--sidebar-selected-text-color);
}
#layout-main-sidebar .main-menu a:not(.active):hover,
#layout-main-sidebar .main-menu p:not(.active):hover {
	box-shadow: inset 0 0 0 999em var(--sidebar-hover-bkg-color);
	border-color: var(--sidebar-hover-bkg-color);
	color: var(--sidebar-hover-text-color);
}
#layout-main-sidebar .main-menu a:hover,
#layout-main-sidebar .main-menu p:hover {
	opacity: 1;
}
#layout-main-sidebar .main-menu a .icon,
#layout-main-sidebar .main-menu p .icon,
#layout-main-sidebar .main-menu a .fa-stack,
#layout-main-sidebar .main-menu p .fa-stack {
	flex: 0 0 1em;
	margin: 0 0.5em 0 0;
	font-size: 1.75em;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--sidebar-icon-color);
}
#layout-main-sidebar .main-menu a .fa-stack .icon,
#layout-main-sidebar .main-menu p .fa-stack .icon {
	margin: 0;
	text-align: unset;
	font-size: 1em;
}
#layout-main-sidebar .main-menu a.active .icon,
#layout-main-sidebar .main-menu p.active .icon,
#layout-main-sidebar .main-menu a.active .fa-stack,
#layout-main-sidebar .main-menu p.active .fa-stack {
	color: var(--sidebar-selected-text-color);
}
#layout-main-sidebar .main-menu .separator {
	font-size: 0.75em;
	font-weight: 300;
	color: var(--sidebar-icon-color);
	margin: 2em 0 0.5em;
	padding-bottom: 0;
	text-transform: uppercase;
}
body:not(.mobile) #layout-main-sidebar.shrank .main-menu .separator {
	font-size: 0.7em;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}
body.mobile .layout .main-sidebar-size-toggle {
	display: none;
}

body:not(.mobile) #layout-main-sidebar {
	transition: width 100ms ease-in;
}
body:not(.mobile) #layout-main-sidebar.shrank {
	width: var(--sidebar-width-shrank);
	transition: width 100ms ease-out;
}
#layout-main-sidebar img.logo-xsmall,
body:not(.mobile) #layout-main-sidebar.shrank img.logo {
	display: none;
}
body:not(.mobile) #layout-main-sidebar img.logo,
body:not(.mobile) #layout-main-sidebar.shrank img.logo-xsmall {
	display: inline-block;
}
body:not(.mobile) #layout-main-sidebar.shrank .main-menu a,
body:not(.mobile) #layout-main-sidebar.shrank .main-menu p {
	justify-content: center;
	padding: 0;
	height: calc(var(--sidebar-width-shrank) - (var(--sidebar-padding-y) * 2));
	width: calc(var(--sidebar-width-shrank) - (var(--sidebar-padding-x) * 2));
}
body:not(.mobile) #layout-main-sidebar.shrank .main-menu a > .icon-stack,
body:not(.mobile) #layout-main-sidebar.shrank .main-menu p > .icon-stack,
body:not(.mobile) #layout-main-sidebar.shrank .main-menu a > .icon:not(.stacked-1):not(.stacked-2),
body:not(.mobile) #layout-main-sidebar.shrank .main-menu p > .icon:not(.stacked-1):not(.stacked-2)
{
	margin: 0;
	font-size: calc(var(--sidebar-width-shrank) * 0.3);
}
body:not(.mobile) #layout-main-sidebar.shrank .main-menu a > span:not(.icon-stack),
body:not(.mobile) #layout-main-sidebar.shrank .main-menu p > span:not(.icon-stack)
{
	display: none;
}
#layout-main-sidebar .sub-menu.expanded {
	background: var(--sidebar-sub-bkg-color);
	box-shadow:
		inset 0 11px 8px -10px var(--sidebar-box-shadow-rgba),
		inset 0 -11px 8px -10px var(--sidebar-box-shadow-rgba);
	border-bottom: 1px solid var(--sidebar-border-color);
	margin-bottom: 1px;
}
#layout-main-sidebar .sub-menu > .sub-menu-title {
	margin: 0;
	cursor: pointer;
}
#layout-main-sidebar .sub-menu > .sub-menu-title > .icon {
	color: var(--palette-orange);
	background: -webkit-linear-gradient(#fdad54, #db8222);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
#layout-main-sidebar .sub-menu.expanded > .sub-menu-title {
	font-weight: 600;
}
#layout-main-sidebar .main-menu .sub-menu a {
	padding-left: 1em;
}
#layout-main-sidebar .sub-menu a,
#layout-main-sidebar .sub-menu p {
	color: var(--sidebar-sub-text-color);
}
#layout-main-sidebar .sub-menu a.active {
	background: var(--sidebar-sub-selected-bkg-color);
	border-color: var(--sidebar-sub-selected-border-color);
	color: var(--sidebar-sub-selected-text-color);
}
#layout-main-sidebar .sub-menu a:not(.active):hover,
#layout-main-sidebar .sub-menu p:not(.active):hover {
	box-shadow: inset 0 0 0 999em var(--sidebar-sub-hover-bkg-color);
	border-color: var(--sidebar-sub-selected-border-color);
	color: var(--sidebar-sub-hover-text-color);
	transition: var(--transition-hover-in);
}

body.dialogs-visible .layout-content, body.popups-visible .layout-content {
	overflow: hidden;
}

.header-left .header-item-wrapper:last-child > .dropdown-content {
	left: 5px;
}
.header-right .header-item-wrapper > .dropdown-content {
	z-index: calc(var(--z-index-popup) + 10);
}

.page-above-title {
	margin-top: calc(0px - var(--padding-section) / 2);
	color: var(--text-color-faded);
}

.page-title {
	margin-top: 0;
	position: sticky;
	top: -1px;
	left: 0;
	z-index: 1;
	padding: 10px 0;
	margin-bottom: calc(0.67em - 10px);
	background: var(--background-color-main);
}
.page-title.stuck {
	box-shadow: var(--page-title-box-shadow),
	-5px 0 0 var(--background-color-main),
	5px 0 0 var(--background-color-main);
	border-bottom: 1px solid var(--page-title-border-color);
	z-index: 2;
}
.page-title > .content {
	transition: font-size 100ms ease-out;
}
.page-title.stuck > .content {
	font-size: 0.75em;
	padding: calc(0.167em + 1px) 0;
	transition: font-size 200ms ease-in;
}
.page-title.with-actions {
	display: flex;
	flex-flow: row nowrap;
}
.page-title.with-actions .content {
	flex: 1 1 100%;
	min-width: 0;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}
.page-title.with-actions .actions {
	flex: 0 0 auto;
	padding-left: 20px;
	display: flex;
	flex-flow: row nowrap;
}
.page-title.with-actions .actions .action-button,
.page-title.with-actions .actions .action-button > [data-tooltipped] {
	display: flex !important;
	align-items: center !important;
}
.page-title.with-actions .actions .action-button {
	margin: 0 0 0 10px;
}
.page-title.with-actions .actions .action-button:first-child {
	margin-left: 0;
}
.page-title.with-actions .actions .action-button .button,
.page-title.with-actions .actions .action-button .button-component {
	margin: 0;
}


/* Blank layout */
.layout-blank .page-content {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}