.f-left { float: left !important; }
.f-right { float: right !important; }
.f-clear { clear: both !important; }
.full-width { width: 100% !important; }
.text-right { text-align: right !important; }
.text-left { text-align: left !important; }
.text-center { text-align: center !important; }
.bold { font-weight: bold !important; }

.sup { vertical-align: super; font-size: 0.7em; }
.sub { vertical-align: sub; font-size: 0.7em; }

.no-display { display: none !important; }
.no-padding { padding: 0 !important; }
.no-wrap { white-space: nowrap !important; }
.no-margin { margin: 0 !important; }
.no-border-radius { border-radius: 0 !important; }
.max-height { height: 100% !important; }

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

.flipH {
	-moz-transform: scale(-1, 1);
	-webkit-transform: scale(-1, 1);
	-o-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
}
.flipV {
	-moz-transform: scale(1, -1);
	-webkit-transform: scale(1, -1);
	-o-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	transform: scale(1, -1);
}

.vert-align-wrapper { display: table; position:absolute; height:100%; width:100%; }
.vert-align-text { display: table-cell; vertical-align: middle; text-align:center; }
.vert-align-wrapper small { display: block; }

.default-color { color: var(--default-background) !important; }
.action-color { color: var(--action-color-main) !important; }
.info-color { color: var(--info-background) !important; }
.help-color { color: var(--help-background) !important; }
.success-color { color: var(--success-background) !important; }
.warning-color { color: var(--warning-background) !important; }
.error-color { color: var(--error-background) !important; }

.center-content {
	display: flex !important;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}
.center-content-vertical {
	display: flex !important;
	flex-flow: row nowrap;
	align-items: center;
}
.center-content-horizontal {
	display: flex !important;
	flex-flow: row nowrap;
	justify-content: center;
}

.blur {
	filter: blur(var(--blur-overlay));
}
.no-blur {
	filter: blur(0);
}

.cursor-not-allowed { cursor: not-allowed !important; }
.cursor-pointer { cursor: pointer !important; }

/* Clearfix */
.clearfix:before, .clearfix:after { content: " "; display: table;}
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }