.formula-editor-component.default-style {
	--action-button-width: 12px;
	--action-button-height: 24px;
	
	height: 400px;
	display: flex;
	flex-flow: column nowrap;
	font-size: 1rem;
}

.formula-editor-component.default-style .toolbar {
	flex: 0 0 auto;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.formula-editor-component.default-style .formula-editor-wrapper {
	flex: 1 1 100%;
	min-height: 0;
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
}
.formula-editor-component.default-style .formula-editor {
	flex: 1 1 100%;
	min-width: 0;
	overflow-y: auto;
	border: var(--form-control-border);
	border-top: none;
	padding: 5px 5px 5px 10px;
	display: flex;
	flex-flow: row wrap;
	align-content: flex-start;
	align-items: center;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
.formula-editor-component.default-style .formula-editor:focus {
	border-color: var(--action-color-main);
	outline: 0;
	box-shadow: 0 0 0 .2rem var(--action-color-faded);
}

.formula-editor-component.default-style .action-wrapper {
	height: var(--action-button-height);
}
.formula-editor-component.default-style .action-wrapper .action-dropdown,
.formula-editor-component.default-style .action-wrapper .action-dropdown .dropdown-label,
.formula-editor-component.default-style .action-wrapper .action-dropdown .dropdown-tooltip {
	display: block !important;
	height: 100%;
	line-height: 1;
}
.formula-editor-component.default-style .action-wrapper .dropdown-content {
	left: calc(0px - var(--action-button-width) / 2);
}
.formula-editor-component.default-style .formula-editor .action-btn {
	width: var(--action-button-width);
	height: var(--action-button-height);
	padding: 0;
	margin: 0;
	border-style: dashed;
	border-color: var(--form-control-border);
	opacity: 0.3;
}
.formula-editor-component.default-style .formula-editor .action-btn:hover {
	opacity: 1;
}
.formula-editor-component.default-style .formula-editor .action-dropdown .dropdown-content {
	width: 253px;
}
.formula-editor-component.default-style .formula-editor .action-dropdown .dropdown-item.toolbar-item {
	padding-top: 0;
	padding-right: 0;
	padding-left: 0;
}
.formula-editor-component.default-style .formula-editor .action-dropdown .dropdown-item.toolbar-item:hover {
	background: unset;
}
.formula-editor-component.default-style .formula-editor .action-dropdown .dropdown-item.toolbar-item .button-component:hover {
	background: var(--dropdown-background-hover);
}

.formula-editor-component.default-style .item-wrapper {
	margin: 5px;
	font-size: 0.875em;
	border: 1px solid var(--border-color-main);
	padding: 5px 10px 5px 9px;
	flex: 0 0 auto;
	min-height: 32px;
	text-align: center;
	cursor: pointer;
	background: var(--background-color-secondary);
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}
.formula-editor-component.default-style .item-wrapper.selected {
	/*border-color: var(--action-color-main);
	outline: 0;
	box-shadow: 0 0 0 .2rem var(--action-color-faded);*/
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
	border-color: var(--action-color-main);
}
.formula-editor-component.default-style .item-wrapper .item-icon {
	font-size: 0.875em;
}

.formula-editor-component.default-style .item-wrapper.type-operator {
	font-weight: 900;
}

.formula-editor-component.default-style .properties {
	display: flex;
	flex-flow: column nowrap;
}
.formula-editor-component.default-style .properties > .separator {
	flex: 0 0 auto;
	margin: 0;
	padding: 5px 10px;
	font-size: 0.875em;
	font-weight: 600;
}
.formula-editor-component.default-style .properties > .separator .icon {
	color: var(--icon-action-color);
}
.formula-editor-component.default-style .properties > .content {
	flex: 1 1 100%;
	min-height: 0;
	overflow: hidden;
}
.formula-editor-component.default-style .properties > .content.no-item-selected {
	padding: 10px;
	font-size: 0.875em;
	line-height: 1.2;
	font-style: italic;
	text-align: center;
	color: var(--text-color-faded);
}
.formula-editor-component.default-style .properties .form-wrapper {
	margin: 0;
	padding: 0;
}
.formula-editor-component.default-style .properties {
	flex: 0 0 250px;
	background: var(--background-color-main);
	border: var(--form-control-border);
	border-width: 0 1px 1px 0;
}
.formula-editor-component.default-style .properties .form-field.label-position-aligned {
	--input-border-radisu: 0;
	--input-font-size: 0.75rem;
	--input-height: 0;

	min-height: var(--input-height);
	margin: 0 !important;
	border-bottom: var(--form-control-border);
}
.formula-editor-component.default-style .properties .form-field.label-position-aligned > .form-field-label {
	padding: 0 10px;
	flex: 0 0 40%;
	font-size: 0.75em;
	justify-content: flex-start;
	text-align: left;
	margin-right: 0;
	border-right: var(--form-control-border);
}
.formula-editor-component.default-style .properties .form-field.label-position-aligned .form-control {
	--form-control-border: none;
}

.formula-editor-component.default-style .properties .content.type-operator .select-input__control,
.formula-editor-component.default-style .properties .content.type-parentheses .select-input__control {
	align-items: stretch;
}
.formula-editor-component.default-style .properties .content.type-operator .select-input__value-container,
.formula-editor-component.default-style .properties .content.type-parentheses .select-input__value-container,
.select-input.formula-editor-select .select-input__option {
	padding-top: 0.25em;
	padding-bottom: 0.25em;
	line-height: 1;
}
.formula-editor-component.default-style .properties .content.type-operator .select-input__single-value,
.formula-editor-component.default-style .properties .content.type-parentheses .select-input__single-value,
.select-input.formula-editor-select .select-input__option {
	font-size: 20px;
	font-weight: 600;
}

.formula-editor-component.default-style .properties .dynamic-values-tree {
	border: none;
	background: none;
	height: 100%;
	overflow-y: auto;
	padding: 10px;
}

.formula-editor-component.default-style .properties > .actions {
	flex: 0 0 auto;
	padding: 0;
	border-top: var(--form-control-border);
}