.input:read-only {
	cursor: default;
}

.input:disabled {
	cursor: not-allowed;
}

.buttonOnlyWrapper {
	position: relative;
}
.buttonOnlyWrapper .input.buttonOnly {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0 !important;
}
.button {
	
}

.withClearWrapper {
	width: 100%;
	position: relative;
}
.clearButton {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	height: calc(100% - 2px);
	width: 32px;
}
.withClearWrapper input[type='file'] {
	padding-right: calc(var(--input-height) / 2 - 14px + 34px);
}