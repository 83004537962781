.spinner {
	display: inline-block;
	background: transparent;
	border-radius: 50%;
	border-style: solid;
	border-color: var(--spinner-color);
	border-top-color: transparent;
	border-bottom-color: transparent;
	animation: spinner1 800ms ease infinite;
}
@keyframes spinner1 {
	to {
		transform: rotate(360deg);
	}
}