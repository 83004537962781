body.skin-light {
	--features-title-shadow:
		2px 2px 0 rgba(0, 0, 0, 0.5),
		1px -1px 0 rgba(0, 0, 0, 0.25),
		-1px 1px 0 rgba(0, 0, 0, 0.25),
		1px 1px 0 rgba(0, 0, 0, 0.25);
}
body.skin-dark {
	--features-title-shadow:
		2px 2px 0 rgba(255, 255, 255, 0.5),
		1px -1px 0 rgba(255, 255, 255, 0.25),
		-1px 1px 0 rgba(255, 255, 255, 0.25),
		1px 1px 0 rgba(255, 255, 255, 0.25);
}