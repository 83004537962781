.messages {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	position: fixed;
	bottom: 20px;
	left: 5%;
	width: 90%;
	pointer-events: none;
	touch-action: none;
	z-index: var(--z-index-messages);
}

.empty {
	width: 0;
	height: 0;
}