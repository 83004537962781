.wrapper {
	position: relative;
}

.multipleInputsWrapper {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	max-width: var(--form-field-max-width);
}

.messages {
	position: absolute;
	top: 100%;
	left: 0;
	margin-top: 0;
	font-size: 0.875rem;
	display: inline-block;
	line-height: 1;
}
.messages .message {
	margin-right: 0.5rem;
}

.wrapper .labelWrapper {
	white-space: nowrap;
}

.wrapper .labelContent {
	white-space: normal;
}

.wrapper .requiredIcon {
	line-height: 1;
}