.wrapper {
	width: 100%;
	/*min-height: 180px;*/
	overflow: auto;
	font-size: var(--table-font-size);
	border-width: 1px;
	border-style: solid;
}
.wrapper.empty {
	min-height: 0;
}

.wrapper table.table {
	width: 100%;
	border-spacing: 0;
	-webkit-border-horizontal-spacing: 0;
	-webkit-border-vertical-spacing: 0;
}
.wrapper.v-scroll .table {
	border-right-width: 1px;
	border-right-style: solid;
}

.table th,
.table td {
	border-right-width: 1px;
	border-right-style: solid;
}
.table th:last-of-type,
.table td:last-of-type {
	border-right: none;
}
.table thead th.select-row,
.table tbody td.select,
.table thead th.num-row,
.table tbody .action-cell,
.table tbody .actions-cell {
	width: 1px;
}
.table thead th.num-row .content {

}

.table thead .column {
	
}
.table thead th {
	position: sticky;
	z-index: 1;
	top: 0;
	text-align: left;
	border-bottom-width: 3px;
	border-bottom-style: solid;
	margin: 0;
	padding: 0;
}
.table thead .clear-selection th {
	text-align: center;
	cursor: pointer;
	border-bottom-width: 1px;
	padding-top: 0.35em;
	padding-bottom: 0.35em;
}
.table thead .clear-selection:hover th {
	opacity: 0.8;
}
.table thead .content {
	padding: 0.35em 1em;
}
.table thead th .column-help-icon {
	font-size: 0.75em;
	color: var(--icon-action-color);
	vertical-align: super;
	transition: var(--transition-hover-out);
}
.table thead th:hover .column-help-icon {
	color: var(--icon-action-color-hover);
	transition: var(--transition-hover-in);
}
.table thead th.sortable {
	cursor: pointer;
}
.table thead th.sortable .content {
	padding-left: 0.5em;
}
.table thead th.sortable .sort-icon-main {
	color: var(--icon-action-color);
}
.table thead th.sortable .sort-icon-active {
	color: var(--icon-action-color-hover);
}

.table tbody td {
	margin: 0;
	padding: 0;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}
.table.withPagination tbody tr:last-of-type td {
	border-bottom: none;
}
.table .select-checkbox,
.table tbody .content,
.table tbody .action-cell .action-btn,
.table tbody .actions-cell .action-btn {
	padding: 0.5em 1em;
}
.table thead .select-row .content,
.table tbody .select .content,
.table tbody .action-cell .content,
.table tbody .actions-cell .content {
	padding: 0;
}
.table tbody .action-cell .action-btn,
.table tbody .actions-cell .action-btn,
.table .select-checkbox {
	cursor: pointer;
	text-align: center;
}
.table tbody .action-btn {
	cursor: pointer;
}
.table tbody tr td.actions-cell {
	white-space: nowrap;
	text-align: right;
}
.table tbody .actions-cell .content {
	display: inline-block;
}
.table .select-checkbox {
	display: flex;
	align-items: center;
	justify-content: center;
}

.pagination {
	position: sticky;
	bottom: 0;
	left: 0;
	border-top-width: 3px;
	border-top-style: solid;
	padding: 0.35em 0;
}
.wrapper.v-scroll .pagination {
	border-right-width: 1px;
	border-right-style: solid;
}
.wrapper.h-scroll .pagination {
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.clickable {
	cursor: pointer;
}
.clickable .noData {
	cursor: default;
}
.hover tr td,
.hover tr:hover.noData td {
	box-shadow: inset 0 0 0 999em transparent;
	transition: var(--transition-hover-out);
}
.hover tr:hover td {
	box-shadow: inset 0 0 0 999em var(--table-row-highlight-background);
}