.wrapper {
	display: flex;
	flex-flow: row nowrap;
	font-size: 1em;
}

.wrapper .buttons {
	display: flex;
	flex-flow: row wrap;
}

.wrapper .button {
	min-width: var(--pagination-button-width);
	height: var(--pagination-button-height);
	padding: 0;
	margin: 0 2px;
	cursor: pointer;
	font-size: inherit;
	color: inherit;
}
.wrapper .button.active {
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.wrapper .button:disabled {
	cursor: not-allowed;
}
.wrapper.no-total .button {
	margin: 0;
}
.wrapper.no-total .button:last-of-type {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.wrapper.no-total .button:first-of-type {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.label {
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
}
.wrapper.dynamic:not(.no-pages) .label {
	margin-left: 10px;
}
.wrapper.static:not(.no-pages) .label {
	margin-left: 5px;
	margin-right: 5px;
}