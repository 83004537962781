.wrapper {
	background: var(--card-background-fade);
	padding: 20px;
	margin-top: 20px;
}
.wrapper.withTitle {
	padding-top: 10px;
}

.wrapper .title {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1;
	padding-top: 5px;
	background: var(--card-background-fade);
	display: flex;
	flex-flow: row nowrap;
}
.wrapper .title .separator {
	flex: 1 1 100%;
	font-size: 1em;
	border: none;
	margin-bottom: 0;
}

.wrapper .description {
	font-size: 0.875em;
	color: var(--text-color-subtle);
	margin-bottom: 1em;
}

.wrapper .form {
	display: flex;
	flex-flow: row nowrap;
}
.wrapper .form .field {
	margin-bottom: 0;
}
.wrapper .form .formSidebar {
	flex: 0 0 40px;
	margin-left: 20px;
}
.wrapper .insertBtn {
	width: 100%;
	height: 100%;
	margin: 0;
}