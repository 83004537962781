.dynamic-values-component.default-style {
	
}

.dynamic-values-component.default-style .groups .notice {
	font-size: 0.75em;
	padding: calc(10px - 0.2em) 10px;
	margin: 0.5em 0;
}
.dynamic-values-component.default-style .groups .group,
.dynamic-values-component.default-style .groups .item {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
.dynamic-values-component.default-style .groups .group {
	cursor: pointer;
}
.dynamic-values-component.default-style .groups .group > .label {
	padding: 2px 0;
	cursor: pointer;
}
.dynamic-values-component.default-style .groups .item .label,
.dynamic-values-component.default-style .groups .label > .icon {
	line-height: 1.25;
}
.dynamic-values-component.default-style .groups .item .label {
	padding: 0.25em 0;
	display: flex;
	flex-flow: row nowrap;
	cursor: pointer;
}
.dynamic-values-component.default-style .groups .label > .icon {
	color: var(--palette-orange);
	background: -webkit-linear-gradient(#fdad54, #db8222);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.dynamic-values-component.default-style .groups .item .label > .icon {
	color: var(--text-color-faded);
	background: unset;
	-webkit-background-clip: unset;
	-webkit-text-fill-color: unset;
}
.dynamic-values-component.default-style .groups .item .label:hover,
.dynamic-values-component.default-style .groups .item .label:hover > .icon {
	color: var(--action-color-main);
}
.dynamic-values-component.default-style .groups .item .label:hover > span {
	text-decoration: underline;
}
.dynamic-values-component.default-style .groups .item .item-inner > [data-tooltipped].active .label,
.dynamic-values-component.default-style .groups .item .item-inner > [data-tooltipped].active .label > .icon {
	color: var(--action-color-main);
}
.dynamic-values-component.default-style .groups .item .dropdown-component .dropdown-item {
	font-size: 0.85rem;
}
.dynamic-values-component.default-style .groups .item.selected,
.dynamic-values-component.default-style .groups .item.selected .label-icon {
	color: var(--action-color-main);
}
.dynamic-values-component.default-style .groups .item.selected {
	font-weight: bold;
}

.dynamic-values-component.default-style .groups .item .item-inner[data-tooltipped].active {
	color: var(--action-color-main);
}