.select-column-item {
	position: relative;
	background-color: var(--background-color-secondary);
	border: none;
	box-shadow: none;
	transition: background-color 150ms ease-out;
}
.select-column-item:hover {
	background-color: var(--action-color-faded);
	transition: background-color 200ms ease-in;
}

.select-column-item.sortable-chosen {
	background: var(--action-color-faded);
	outline: 2px dotted var(--action-color-main);
	transition: box-shadow 250ms ease-in;
}

.select-column-item .remove-btn-dropdown {
	position: absolute;
	top: -10px;
	right: -10px;
}
.select-column-item .remove-btn-dropdown .remove-btn {
	font-size: 12px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	padding: 0;
	margin: 0;
	background-color: hsl(var(--error-background-h), calc(var(--error-background-s) - 15%), calc(var(--error-background-l) + 15%));
	color: var(--error-text);
	border: none;
	box-shadow: var(--button-shadow);
	opacity: 0 !important;
}
.select-column-item:hover .remove-btn-dropdown .remove-btn {
	display: block;
	opacity: 1 !important;
	background-color: var(--error-background);
}
.select-column-item:hover .remove-btn-dropdown .remove-btn:hover {
	background-color: hsl(var(--error-background-h), var(--error-background-s), calc(var(--error-background-l) - 15%));
	color: var(--error-text);
}

.select-column-item .handle {
	cursor: move;
	flex: 1 1 auto;
	display: flex;
	flex-flow: column nowrap;
	justify-content: stretch;
	padding: 10px 10px 5px;
}
.select-column-item .card-header {
	background-color: var(--card-background);
	font-size: 0.75rem;
	color: var(--text-color-faded);
	font-weight: normal;
	padding: 10px 10px 0;
	border: var(--form-control-border);
	border-width: 1px 1px 0 1px;
	border-top-left-radius: .25em;
	border-top-right-radius: .25em;
	flex: 0 0 auto;
	min-height: 0;
}

.select-column-item .card-content {
	background-color: var(--card-background);
	font-size: 0.875rem;
	font-weight: 600;
	border: var(--form-control-border);
	border-width: 0 1px 1px 1px;
	border-bottom-left-radius: .25em;
	border-bottom-right-radius: .25em;
	padding: 5px 10px 10px;
	line-height: 1;
	flex: 1 1 auto;
	min-height: 0;
	display: flex;
	flex-flow: row nowrap;
}

.select-column-item .card-actions {
	justify-content: space-between;
	padding: 0 10px 10px;
	border: none;
	background: none;
	border-bottom-left-radius: var(--card-border-radius);
	border-bottom-right-radius: var(--card-border-radius);
}
.select-column-item .card-actions > * {
	flex: 1 1 calc(25% - 5px);
	margin: 0 5px 0 0;
}
.select-column-item .card-actions > *:last-child {
	margin-right: 0;
}
.select-column-item .card-actions .button-component {
	width: 100%;
	padding: 5px 0;
	font-size: 12px;
	margin: 0;
}
.select-column-item .card-actions .button-component:hover {
	opacity: 1;
	background-color: var(--card-background);
}
.select-column-item .card-actions .button-component.applied {
	position: relative;
}
.select-column-item .card-actions .button-component.applied:after {
	content: "";
	position: absolute;
	top: -4px;
	right: -4px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 14px;
	height: 14px;
	border-radius: 50%;
	font-size: 8px;
	font-weight: 900;
	text-align: center;
	background: var(--warning-background);
	color: var(--success-text);
}

.select-column-item .card-actions .sort-dropdown .dropdown-item {
	white-space: nowrap;
	font-size: 0.813rem;
	padding: 5px 7px;
}
.select-column-item .card-actions .sort-btn {
	position: relative;
}
.select-column-item .card-actions .sort-btn .button-component-icon {
	margin-right: 0;
}
.select-column-item .card-actions .sort-btn .sort-ordinal {
	position: absolute;
	top: -4px;
	right: -4px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 14px;
	height: 14px;
	padding: 2px;
	border-radius: 50%;
	font-size: 8px;
	font-weight: 900;
	text-align: center;
	background: var(--warning-background);
	color: var(--success-text);
}
.select-column-item .card-actions .sort-btn .sort-ordinal > .icon {
	margin-left: 0 !important;
	font-size: 0.875em;
}
.select-column-item .card-actions .sort-btn .sort-ordinal > .icon.fa-arrow-down,
.select-column-item .card-actions .sort-btn .sort-ordinal > .icon.fa-caret-down,
.select-column-item .card-actions .sort-btn .sort-ordinal > .icon.fa-chevron-down {
	margin-top: 1px;
}