/* General ---------------------------------------------------------------------------------------------------------- */
.button {
	display: inline-block;
	padding: 0.5em 1em;
	font: var(--font-main-input);
	font-size: 14px;
	line-height: 1.2;
	border: 1px solid transparent;
	outline: none;
	transition: var(--transition-hover-out);
	margin: 1px;
	border-radius: var(--button-border-radius);
	cursor: pointer;
}

.button:disabled {
	opacity: 0.5 !important;
	cursor: not-allowed;
}

.button.icon-only {
	padding: 0.5em 0.6em;
}

.button.big {
	font-size: 18px;
	font-weight: 300;
}

.button .icon {
	margin-right: 0.5em;
}
.button.icon-only > .icon {
	margin-right: 0;
}


/* Display styles --------------------------------------------------------------------------------------------------- */
.button.default.solid {
	background: var(--button-default-background);
	color: var(--button-default-text);
}
.button.default.solid:hover:not(:disabled) {
	background: var(--button-default-background-hover);
}
.button.default.transparent {
	background: transparent;
	border-color: var(--button-default-background);
	color: var(--button-default-background);
}
.button.default.transparent:hover:not(:disabled) {
	border-color: var(--button-default-background-hover);
	color: var(--button-default-background-hover);
}
.button.default.none {
	color: var(--button-default-background);
}
.button.default.none:hover:not(:disabled) {
	color: var(--button-default-background-hover);
}

.button.subtle.solid {
	background: var(--button-subtle-background);
	color: var(--button-subtle-solid-text);
}
.button.subtle.solid:hover:not(:disabled) {
	background: var(--button-subtle-background-hover);
	color: var(--button-subtle-solid-text-hover);
}
.button.subtle.transparent {
	background: transparent;
	border-color: var(--button-subtle-background);
	color: var(--button-default-background);
}
.button.subtle.transparent:hover:not(:disabled) {
	border-color: var(--button-subtle-background-hover);
	color: var(--button-default-background-hover);
}
.button.subtle.none {
	color: var(--button-default-background);
}
.button.subtle.none:hover:not(:disabled) {
	color: var(--button-default-background-hover);
}

.button.action.solid {
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.button.action.solid:hover:not(:disabled) {
	background: var(--action-color-hover);
}
.button.action.transparent {
	background: transparent;
	border-color: var(--action-color-main);
	color: var(--action-color-main);
}
.button.action.transparent:hover:not(:disabled) {
	border-color: var(--action-color-hover);
	color: var(--action-color-hover);
}
.button.action.none {
	color: var(--action-color-main);
}

.button.success.solid {
	background: var(--success-background);
	color: var(--success-text);
}
.button.success.solid:hover:not(:disabled) {
	background: var(--success-background-hover);
}
.button.success.transparent {
	background: transparent;
	border-color: var(--success-background);
	color: var(--success-background);
}
.button.success.transparent:hover:not(:disabled) {
	border-color: var(--success-background-hover);
	color: var(--success-background-hover);
}
.button.success.none {
	color: var(--success-background);
}

.button.warning.solid {
	background: var(--warning-background);
	color: var(--warning-text);
}
.button.warning.solid:hover:not(:disabled) {
	background: var(--warning-background-hover);
}
.button.warning.transparent {
	background: transparent;
	border-color: var(--warning-background);
	color: var(--warning-background);
}
.button.warning.transparent:hover:not(:disabled) {
	border-color: var(--warning-background-hover);
	color: var(--warning-background-hover);
}
.button.warning.none {
	color: var(--warning-background);
}

.button.error.solid {
	background: var(--error-background);
	color: var(--error-text);
}
.button.error.solid:hover:not(:disabled) {
	background: var(--error-background-hover);
}
.button.error.transparent {
	background: transparent;
	border-color: var(--error-background);
	color: var(--error-background);
}
.button.error.transparent:hover:not(:disabled) {
	border-color: var(--error-background-hover);
	color: var(--error-background-hover);
}
.button.error.none {
	color: var(--error-background);
}


/* Display types ---------------------------------------------------------------------------------------------------- */
.button.none {
	background: transparent;
	border-color: transparent;
	color: inherit;
}


/* Pseudo ----------------------------------------------------------------------------------------------------------- */
.button:focus {
	transition: none;
	outline: 1px dotted var(--button-focus-outline-color);
	-moz-outline-radius: var(--button-border-radius);
	box-shadow: inset 0 0 0 1px var(--background-color-main);
}
.button:hover:not(:disabled) {
	transition: var(--transition-hover-in);
}
.button:active:not(:disabled) {
	transition: none;
	border-color: transparent;
	box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.5);
}