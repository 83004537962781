.wrapper {
	flex: 1 1 100%;
	display: flex;
	flex-flow: column nowrap;
	min-height: 500px;
}

.noDataSource .wrapper {
	min-height: unset;
	margin-bottom: 20px;
}

.noDataSource .actions {
	text-align: right;
}
.noDataSource .actions button {
	font-size: 1.25em;
	font-weight: 400;
}

.wrapper .selectOutputs {
	font-size: 1.25em;
}

.errorActions {
	text-align: left;
	margin-top: 1em;
}

.wrapper .section {
	flex: 1 1 50%;
	overflow: visible;
	min-height: 0;
}
.wrapper .section.collapsed {
	flex-grow: 0;
	flex-basis: auto;
	transition: flex-grow 200ms ease-out;
}

.wrapper .section .selectHeader,
.wrapper .section .filterHeader
{
	flex: 0 0 auto;
	box-shadow: 0 4px 10px 0 rgba(var(--card-shadow-rgb), 0.2);
	display: flex;
	flex-flow: row nowrap;
	padding: 7px 10px 7px 15px;
	cursor: pointer;
}
.wrapper .section.select .selectContent,
.wrapper .section.filter .filterContent
{
	flex: 1 1 100%;
	min-height: 0;
	overflow-y: auto;
	margin: 10px;
	padding: 10px;
}
.wrapper .section .title {
	flex: 1 1 100%;
	min-width: 0;
	display: flex;
	align-items: center;
}
.wrapper .section[data-error] .filterHeader {
	background: var(--error-background);
	color: var(--error-text);
}
.wrapper .section[data-error] .filterHeader .toolbar .button {
	color: var(--error-text);
}
.wrapper .section .toolbar {
	flex: 0 0 auto;
}
.wrapper .section .toolbar .button {
	
}
.wrapper .section .toolbar .sizeBtn > i:before {
	font-size: 1.35em;
	line-height: 0.75em;
}

.wrapper .section.select {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom: none;
}
.wrapper .section.select .columns {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	gap: 1vw;
	flex: unset;
	overflow: visible;
}
.wrapper .section.select .column {
	
}

.wrapper .section.filter {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.wrapper .section.filter .filterContent {
	
}

@media screen and (max-width: 1024px) {
	
}

@media screen and (max-width: 768px) {
	.wrapper,
	.wrapper .section {
		display: block;
		flex: 0 0 auto;
	}
	.wrapper .section {
		margin-bottom: var(--padding-section);
	}
	.wrapper .selectOutputs .selectOutputIcon {
		font-size: 1.25em !important;
	}
}

@media screen and (max-width: 640px) {
	.noDataSource .actions button {
		width: 100%;
		padding: .75em;
	}
	
	.wrapper .selectOutputs {
		grid-template-columns: repeat(2, 1fr) !important;
	}
}

@media screen and (max-width: 480px) {
	.wrapper .selectOutputs {
		grid-template-columns: repeat(1, 1fr) !important;
		gap: 10px !important;
	}
}