.wrapper {
	font-size: var(--input-font-size);
	width: 100%;
}


/* Grid display type ------------------------------------------------------------------------------------------------ */
.wrapper.typeGRID {
	display: grid;
}

/* List display type ------------------------------------------------------------------------------------------------ */
.wrapper.typeLIST {
	display: flex;
	flex-flow: column nowrap;
}


/* Item ------------------------------------------------------------------------------------------------------------- */
.wrapper .item {
	cursor: pointer;
	transition: var(--transition-hover-out);
}
.wrapper .item:not(.selected):hover {
	background-color: var(--card-background-fade);
	transition: var(--transition-hover-in);
}
.wrapper .item.selected {
	background-color: var(--action-color-main);
	color: var(--action-color-contrast-main);
	border-color: var(--action-color-main);
}
.wrapper .item:focus {
	outline: 0;
	box-shadow: 0 0 0 .2rem var(--action-color-faded);
	border-color: var(--action-color-main);
}
.wrapper .item.withIcon {
	
}
.wrapper .item.withoutIcon {

}
.wrapper .item .itemContent {
	padding: 1em;
}
.wrapper .item .itemContent .itemIcon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	text-align: center;
	font-size: 2em;
	width: 1.75em;
	height: 1.75em;
	line-height: 1;
	background: var(--action-color-main);
	color: var(--action-color-contrast-main);
}
.wrapper .item.selected .itemContent .itemIcon {
	background-color: var(--action-color-contrast-main);
	color: var(--action-color-main);
}

.wrapper .item .itemContent .text {
	
}
.wrapper .item .itemContent .itemLabel {
	font-size: 1em;
	font-weight: normal;
	line-height: 1;
	margin-bottom: 0.25em;
}
.wrapper .item .itemContent .itemDesc {
	font-weight: 200;
	line-height: 1.2;
	font-size: 0.875em;
	opacity: 0.75;
}


/* Stacked item display type ---------------------------------------------------------------------------------------- */
.wrapper .item.itemTypeSTACKED .itemContent {
	display: flex;
	align-items: center;
	flex-flow: column nowrap;
}
.wrapper .item.itemTypeSTACKED .itemContent .itemIcon {
	flex: 0 0 auto;
}
.wrapper .item.itemTypeSTACKED.withIcon .text {
	margin-top: 0.5em;
	text-align: center;
}

/* Aligned item display type ---------------------------------------------------------------------------------------- */
.wrapper .item.itemTypeALIGNED .itemContent {
	display: flex;
	align-items: center;
	flex-flow: row nowrap;
}
.wrapper .item.itemTypeALIGNED .itemContent .itemIcon {
	flex: 0 0 auto;
}
.wrapper .item.itemTypeALIGNED.withIcon .text {
	margin-left: 0.5em;
	text-align: left;
}