.wrapper {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
}

.wrapper .row {
	margin-bottom: 0.5em;
}

.wrapper .label {
	margin-right: 0.25em;
}

.wrapper .stacktrace {
	flex: 1 1 100%;
	border-width: 0 0 1px;
	width: 100%;
	resize: vertical;
	padding: 0.5em 1em 0;
	font: var(--font-main-mono);
	font-size: 13px;
	font-weight: 450;
	white-space: pre;
	background: var(--card-background-fade);
}
.wrapper .stacktrace:focus {
	outline: none;
}
.wrapper .stacktrace.wrap {
	white-space: break-spaces;
}
.wrapper .stacktraceToolbar {
	margin-top: 0.5em;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}