.wrapper {
	
}

.wrapper .reportTemplatesCell {
	display: flex;
	flex-flow: row wrap;
	margin: -5px;
}
.wrapper .reportTemplatesCellItem {
	cursor: help;
}
.wrapper .reportTemplatesCellItemIcon {
	margin-right: 0.25em !important;
}