#reports-page .main-search .options-form-inner {
	display: flex;
	flex-flow: column wrap;
	max-height: 300px;
	max-width: 100%;
	overflow: visible;
	padding-right: 10px;
	margin-top: 12px;
}
#reports-page #reports-page-scheduled-reports .main-search .options-form-inner {
	max-height: 250px;
}

#reports-page .main-search .options-form-inner .form-field.label-position-aligned {
	width: 50%;
	margin: 0.75rem 0;
}


#reports-page .steaming-coffee {
	margin-right: 0.5em;
}

#reports-page #main-page-table thead th:nth-last-child(2),
#reports-page #main-page-table tbody td:nth-last-child(2) 
{
	border-right: none;
}

#reports-page #main-page-table .status-cell .icon {
	width: 1em;
	text-align: center;
}