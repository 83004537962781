.title {
	display: flex;
	flex-flow: row nowrap;
}
.title .content {
	flex: 1 1 100%;
	min-width: 0;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}
.title .titleInput {
	flex: 1 1 100%;
	min-width: 0;
	padding: 0;
	line-height: 1;
	background: none;
	border-color: transparent;
}
.title .titleInput:hover {
	border: var(--form-control-border);
}
.title .titleInput:focus {
	background: var(--form-control-background);
}
.title .actions {
	flex: 0 0 auto;
	padding-left: 20px;
	display: flex;
	flex-flow: row nowrap;
}
.title .actions .templateBtnDropdown,
.title .actions .buildBtn
{
	margin-right: 10px;
}
.title .actions .templateBtnWrapper {
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
}
.title .actions .templateBtn {
	padding-right: 0.75em;
}
.title .actions .templateBtnArrow {
	margin-left: 0.5em;
	margin-top: -0.5em;
	margin-bottom: -0.5em;
}
.title .actions .templateBtnItem {
	font-weight: 600;
}
.title .actions .templateBtnItem.active {
	color: var(--action-color-main);
}
.title .actions .templateBtnIcon {
	margin-right: 0;
}
.title .actions .buildBtnWrapper {
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
}
.title .actions .buildBtn {
	padding-right: 0.75em;
}
.title .actions .buildBtnArrow {
	margin-left: 0.5em;
	margin-top: -0.5em;
	margin-bottom: -0.5em;
}
.title .actions .buildBtnItem {
	font-weight: 600;
}