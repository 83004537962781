.wrapper {
	display: inline-block;
}

.content {
	margin: -4px -8px;
	padding: 0;
	background: var(--dropdown-background);
	scrollbar-width: thin;
	text-align: left;
}
.content.verScroll {
	overflow-y: scroll;
}

.content .contentInner {
	font-size: inherit;
}
.content .contentInner .loading {
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.content .contentInner .item {
	font-size: inherit;
	opacity: 1;
}
.content .contentInner .item:not(:first-child),
.content .contentInner .item:not(:last-child) {
	border-radius: 0;
}
.content .contentInner .item[data-disabled='true'] {
	cursor: default;
}
.content .contentInner .item[data-disabled='true']:hover {
	background: none;
}