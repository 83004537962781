/* Custom stacked icons --------------------------------------------------------------------------------------------- */
/* User roles stacked icon */
.fa-stack.user-roles {
	width: 1em;
	height: 1em;
	line-height: 1;
}
.fa-stack.user-roles .icon.stacked-1 {
	font-size: 0.9em;
	left: -0.071em;
}
.fa-stack.user-roles .icon.stacked-2 {
	font-size: 0.6em;
	left: 0.7em;
	top: 0.7em;
}

/* Stacked card icons */
.fa-stack.card-stack {
	overflow: hidden;
}
.fa-stack.card-stack .icon.stacked-1 {
	font-size: 0.9em;
	left: -0.3em;
	top: -0.05em;
}
.fa-stack.card-stack .icon.stacked-2 {
	font-size: 0.6em;
	left: 0.65em;
	top: -0.7em;
}

/* Function/formula icon */
.icon-function {
	font-weight: 900;
	line-height: 1;
}
.icon-function:before {
	content: 'ƒ';
	font-size: inherit;
}
.icon-function:after {
	content: '(x)';
	vertical-align: sub;
	font-size: 0.5em;
	line-height: 1;
	margin-left: -0.25em;
}

/* Steaming coffee icon */
.fa-stack.steaming-coffee {
	width: 1em;
	height: 1em;
	line-height: 1;
	position: relative;
}
.fa-stack.steaming-coffee .icon.stacked-1 {

}
.fa-stack.steaming-coffee .icon.stacked-2 {
	position: absolute;
	font-size: 0.75em;
	top: -0.65em;
	z-index: 0;
	opacity: 0;
	animation: coffee-steam 4s ease-out infinite;
}
@keyframes coffee-steam {
	0% {
		opacity: 0;
		transform: scale(0.35);
	}
	50% {
		transform: scale(0.75);
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: scale(1.25);
	}
}

/* Download and delete icon */
.fa-stack.download-delete {
	width: 1em;
	height: 1em;
	line-height: 1;
	position: relative;
}
.fa-stack.download-delete .icon.stacked-1 {
	left: -0.125em;
	top: -1px;
}
.fa-stack.download-delete .icon.stacked-2 {
	font-size: 0.875em;
	position: absolute;
	left: auto;
	right: -0.5em;
	bottom: 0;
	width: 1em;
	height: 1em;
}

/* Word wrap icon */
.fa-stack.word-wrap {
	height: 1em;
	width: 1em;
	line-height: 1em;
}
.fa-stack.word-wrap .icon.stacked-2 {
	font-size: 0.75em !important;
	margin-left: 0.75em !important;
	transform: scaleY(-1) !important;
	margin-top: 0.6em !important;
}

/* Schedule icon */
.fa-stack.schedule {
	height: 1em;
	width: 1em;
	line-height: 1em;
}
.fa-stack.schedule .icon.stacked-2 {
	font-size: 0.5em !important;
	width: 1em !important;
	height: 1em !important;
	line-height: 1 !important;
	left: calc(50% - 1em/2);
	top: calc(50% - 1em/2 + 0.25em);
}

/* File template */
.fa-stack.file-template {
	height: 1em;
	width: 1em;
	line-height: 1em;
}
.fa-stack.file-template .icon.stacked-2 {
	font-size: 0.5em !important;
	width: 1em !important;
	height: 1em !important;
	line-height: 1 !important;
	left: calc(50% - 1em/2 + 0.025em);
	top: calc(50% - 1em/2 - 0.025em);
}

/* File clock */
.fa-stack.file-clock {
	height: 1em;
	width: 1em;
	line-height: 1em;
}
.fa-stack.file-clock .icon.stacked-2 {
	font-size: 0.5em !important;
	width: 1em !important;
	height: 1em !important;
	line-height: 1 !important;
	left: calc(50% - 1em/2 + 0.025em);
	top: calc(50% - 1em/2 - 0.025em);
}


/* Card error */
.fa-stack.card-times {
	height: 1em;
	width: 1em;
	line-height: 1em;
}
.fa-stack.card-times .icon.stacked-1 {
	font-size: 1.2em !important;
	line-height: 0.8;
}
.fa-stack.card-times .icon.stacked-2 {
	font-size: 0.5em !important;
	line-height: 1 !important;
	position: absolute;
	right: -0.75em;
	bottom: -0.25em;
}